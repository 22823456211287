import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { addEntities, addEntity, updateEntity, withEntities } from "@ngrx/signals/entities";
import { ILeague } from "../models/league";
import { LeagueService } from "../services/league.service";
import { computed, inject } from "@angular/core";
import { ITeam } from "../models/team";
import { filter } from "rxjs";

type LeagueState = {
	selectedLeagueId: string | null;
	loaded: boolean;
};

const initialState: LeagueState = {
	selectedLeagueId: null,
	loaded: false
};

export const LeagueStore = signalStore(
	{ providedIn: "root", protectedState: false },
	withState(initialState),
	withEntities<ILeague>(),
	withMethods((store, leagueService = inject(LeagueService)) => ({
		loadAllLeagues(): void {
			patchState(store, { loaded: false });
			leagueService.getAll().subscribe((leagues) => {
				patchState(store, addEntities(leagues));
				patchState(store, { loaded: true });
			});
		},
		loadActiveLeagues(): void {
			patchState(store, { loaded: false });
			leagueService.getActive().subscribe((leagues) => {
				patchState(store, addEntities(leagues));
				patchState(store, { loaded: true });
			});
		},
		setCurrentLeague(id: string | null): void {
			patchState(store, { selectedLeagueId: id });

			console.log(store);

			// Get live updates
			if (id != null) {
				leagueService
					.getLiveUpdatesByID(id)
					.pipe(filter((x) => !!x))
					.subscribe((league) => {
						if (store.ids().includes(id)) {
							patchState(store, updateEntity({ id: league!!.id, changes: { ...league } }));
						} else {
							patchState(store, addEntity(league!!));
						}
					});
			}
		}
	})),
	withHooks({
		onInit(store) {
			// TODO: If user is admin should load all leagues
			store.loadActiveLeagues();
		}
	}),
	withComputed(({ entities, selectedLeagueId }) => ({
		activeLeagues: computed(() => entities().filter((x) => x.status === "Active")),
		promotedLeague: computed(() => entities().find((x) => x.isPromotional)),
		currentLeague: computed(() => entities().find((x) => x.id === selectedLeagueId()))
	})),
	withComputed((store) => ({
		teams: computed(() => store.currentLeague()?.teams),
		games: computed(() => store.currentLeague()?.games),
		rounds: computed(() => new Set(store.currentLeague()?.games.map((x) => x.round))),
		teamsByDivision: computed(() => {
			const teams = store.currentLeague()?.teams;

			if (!teams || teams.length === 0) {
				return new Map<string, ITeam[]>();
			}

			return teams.reduce((map, team) => {
				if (!map.has(team.division)) {
					map.set(team.division, [team]);
				} else {
					map.get(team.division)?.push(team);
				}
				return map;
			}, new Map<string, ITeam[]>());
		})
	}))
);
