<button mat-button color="primary" (click)="goToSignup()">New User? Sign up Here</button>
<form [formGroup]="loginForm" class="generic-login-form" (submit)="login()">
	<mat-form-field>
		<mat-label>Email</mat-label>
		<input matInput type="email" formControlName="email" autocomplete="email username" />
		<mat-error *ngIf="email.hasError('email') && !email.hasError('required')"> Please enter a valid email address </mat-error>
		<mat-error *ngIf="email.hasError('required')"> Email is <strong>required</strong> </mat-error>
	</mat-form-field>
	<mat-form-field>
		<mat-label>Password</mat-label>
		<input matInput type="password" formControlName="password" autocomplete="current-password" />
		<mat-error *ngIf="email.hasError('required')"> Passsword is <strong>required</strong> </mat-error>
	</mat-form-field>
	<mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
	<button *ngIf="!failedVerification" mat-raised-button color="accent" [disabled]="!loginForm.valid || loginForm.pristine || updating">Login</button>
	<button *ngIf="failedVerification" mat-raised-button color="warn" [disabled]="!loginForm.valid || loginForm.pristine || updating" (click)="resendSendVerificationEmail()">Go To Verification</button>
</form>
<button mat-button color="warn" (click)="goToReset()">Forgot Password</button>
