import { Action, createReducer, on } from "@ngrx/store";
import { IGroup } from "src/app/models/group";
import * as groupActions from "./group.actions";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { logoutSuccess } from "../app/app.actions";
import { ICache } from "src/app/models/cache";

export interface GroupState extends EntityState<ICache<IGroup>> {
	selectedGroupId: string | null;
	liveData: boolean;
	joiningGroup: boolean;
	leavingGroup: boolean;
	publicRoomsWithCapacity: IGroup[];
}

function selectGroupId(a: ICache<IGroup>): string {
	return a.id;
}

const adapter: EntityAdapter<ICache<IGroup>> = createEntityAdapter<ICache<IGroup>>({
	selectId: selectGroupId
});

export const InitialState: GroupState = adapter.getInitialState({
	selectedGroupId: "",
	liveData: false,
	joiningGroup: false,
	leavingGroup: false,
	publicRoomsWithCapacity: []
});

const reducer = createReducer(
	InitialState,
	on(groupActions.loadGroupSuccess, (state, { group }) => adapter.upsertOne({ ...group, lastUpdated: new Date() }, state)),
	on(groupActions.loadGroupsSuccess, (state, { groups }) =>
		adapter.upsertMany(
			groups.filter((g) => !!g?.id).map((g) => ({ ...g, lastUpdated: new Date() })),
			state
		)
	),
	on(groupActions.setSelectedGroupSuccess, (state, { groupId }) => ({ ...state, selectedGroupId: groupId })),
	on(groupActions.acceptGroupInvite, (state) => ({ ...state, joiningGroup: true })),
	on(groupActions.acceptGroupInviteSuccess, (state) => ({ ...state, joiningGroup: false })),
	on(groupActions.acceptGroupInviteError, (state) => ({ ...state, joiningGroup: false })),
	on(groupActions.leaveGroup, (state) => ({ ...state, leavingGroup: true })),
	on(groupActions.leaveGroupSuccess, (state, { groupId }) => {
		let returnState = { ...state, leavingGroup: false };

		if (groupId === state.selectedGroupId) {
			returnState.selectedGroupId = "";
		}

		return state;
	}),
	on(groupActions.leaveGroupError, (state) => ({ ...state, leavingGroup: false })),
	on(groupActions.clearGroups, (state) => adapter.removeAll(state)),
	on(groupActions.upsertPublicRoomsWithCapacity, (state, { groups }) => ({ ...state, publicRoomsWithCapacity: groups })),
	on(logoutSuccess, () => InitialState)
);

export function groupReducers(state: GroupState | undefined, action: Action) {
	return reducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectGroupIds = selectIds;

// select the dictionary of user entities
export const selectGroupEntities = selectEntities;

// select the array of users
export const selectAllGroups = selectAll;

// select the total user count
export const selectGroupTotal = selectTotal;
