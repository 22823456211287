import { CdkCellDef, CdkColumnDef, CdkHeaderCellDef } from "@angular/cdk/table";
import { TextFieldModule } from "@angular/cdk/text-field";
import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, filter, map, Observable, switchMap, take, takeUntil } from "rxjs";
import { SportsDBIntegrationKey } from "src/app/models/constants";
import { IEvent } from "src/app/models/event";
import { IGame, IGameIntegrationInfo, ISeries } from "src/app/models/game";
import { ILeagueIntegrationInfo } from "src/app/models/integration";
import { ILeague, IRegion } from "src/app/models/league";
import { ITeam, ITeamIntegrationInfo } from "src/app/models/team";
import { EventService } from "src/app/services/event.service";
import { IntegrationService } from "src/app/services/integration.service";
import { LeagueService } from "src/app/services/league.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { LoadingButtonComponent } from "src/app/shared/components/loading-button/loading-button.component";
import { GameEditDialogComponent } from "src/app/shared/dialogs/game-edit-dialog/game-edit-dialog.component";
import { IntegrationDialogComponent } from "src/app/shared/dialogs/integration-dialog/integration-dialog.component";
import { SeriesEditDialogComponent } from "src/app/shared/dialogs/series-edit-dialog/series-edit-dialog.component";
import { compare } from "src/app/shared/functions/compare.function";
import { LeagueStore } from "src/app/state/league.store";

/*
	TODO:
	Teams table should include integration data in someway

*/

@Component({
	selector: "am-league-admin",
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatSelectModule,
		MatInputModule,
		MatTabsModule,
		MatCheckboxModule,
		MatTableModule,
		MatButtonModule,
		CdkColumnDef,
		CdkHeaderCellDef,
		CdkCellDef,
		MatExpansionModule,
		LoadingButtonComponent,
		MatTooltipModule,
		MatCardModule,
		MatDividerModule,
		TextFieldModule
	],
	templateUrl: "./league-admin.component.html",
	styleUrl: "./league-admin.component.scss"
})
export class LeagueAdminComponent extends BaseComponent implements OnInit {
	readonly leagueStore = inject(LeagueStore);

	leagueId: string;
	leagueForm: FormGroup;

	sportOptions: string[] = ["Mens College Basketball", "NHL", "NBA", "NFL", "League of Legends Worlds", "Womens College Basketball"];
	typeOptions: string[] = ["Basketball", "Football", "Baseball", "Hockey", "Generic"];
	statusOptions: string[] = ["Draft", "Active", "Archived"];

	gameStatus = new FormControl(null);
	gameRound = new FormControl(null);
	showFinalGames = new FormControl(true);

	teamColumns: string[] = [
		"seed",
		"displayName",
		"abbreviation",
		"record",
		"division",
		"hexcode",
		"logo",
		"isEliminated",
		"useAbbreviation",
		"actions"
	];

	importing$ = new BehaviorSubject<boolean>(false);
	saving$ = new BehaviorSubject<boolean>(false);
	integrationLeagues$: Observable<ILeagueIntegrationInfo[]>;
	integrationSeasons$: Observable<string[]>;
	events$: Observable<IEvent[]>;

	constructor(
		private activatedRoute: ActivatedRoute,
		private fb: FormBuilder,
		private integrationService: IntegrationService,
		private leagueService: LeagueService,
		private eventService: EventService,
		private dialog: MatDialog,
		private router: Router
	) {
		super();
	}

	get integrationData(): FormGroup {
		return this.leagueForm?.get("integrationData") as FormGroup;
	}

	get integrationLeagueId(): FormControl {
		return this.integrationData.get("leagueId") as FormControl;
	}

	get integrationSeasonId(): FormControl {
		return this.integrationData.get("seasonId") as FormControl;
	}

	get sport(): FormControl {
		return this.leagueForm?.get("sport") as FormControl;
	}

	get type(): FormControl {
		return this.leagueForm?.get("type") as FormControl;
	}

	get year(): FormControl {
		return this.leagueForm.get("year") as FormControl;
	}

	get teams(): FormArray {
		return this.leagueForm.get("teams") as FormArray<FormGroup>;
	}

	get games(): FormArray<FormGroup> {
		return this.leagueForm.get("games") as FormArray<FormGroup>;
	}

	get series(): FormArray<FormGroup> {
		return this.leagueForm.get("series") as FormArray<FormGroup>;
	}

	get regions(): FormArray<FormGroup> {
		return this.leagueForm.get("regions") as FormArray<FormGroup>;
	}

	get gameControls(): FormGroup[] {
		return this.games.controls as FormGroup[];
	}

	get canImport(): boolean {
		return !!this.integrationLeagueId?.value && !!this.integrationSeasonId?.value && !!this.sport?.value && !!this.type?.value;
	}

	ngOnInit(): void {
		this.leagueId = this.activatedRoute.snapshot.params["leagueId"];
		this.leagueStore.loadActiveLeagues();

		this.integrationLeagues$ = this.integrationService.getLeagues();

		if (this.leagueId) {
			this.events$ = this.eventService.getEventsByLeague(this.leagueId);
			this.leagueService
				.getLiveUpdatesByID(this.leagueId)
				.pipe(take(1))
				.subscribe((league) => this.createForm(league));
		} else {
			this.createForm();
		}
	}

	addTeam(): void {
		this.teams.push(this.createTeamGroup());
	}

	deleteTeam(index: number): void {
		this.teams.removeAt(index);
	}

	addGame(): void {
		const gameForm = this.createGameForm();

		this.games.push(gameForm);

		this.editGame(gameForm.value);
	}

	deleteGame(index: number): void {
		this.games.removeAt(index);
	}

	addSeries(): void {
		const seriesForm = this.createSeriesForm();

		this.series.push(seriesForm);

		this.editSeries(seriesForm.value);
	}

	deleteSeries(index: number): void {
		this.games.removeAt(index);
	}

	addRegion(): void {
		this.regions.push(this.createRegionGroup());
	}

	deleteRegion(index: number): void {
		this.regions.removeAt(index);
	}

	import(): void {
		this.importing$.next(true);
		this.dialog
			.open(IntegrationDialogComponent, {
				data: {
					leagueId: this.integrationLeagueId.value,
					seasonId: this.integrationSeasonId.value,
					sport: this.sport.value,
					type: this.type.value
				}
			})
			.afterClosed()
			.pipe(take(1))
			.subscribe((result: { teams: ITeamIntegrationInfo[]; games: IGameIntegrationInfo[] }) => {
				if (result == null) {
					this.importing$.next(false);
					return;
				}

				result.teams.forEach((team) => {
					const newTeam = {
						id: crypto.randomUUID(),
						displayName: team.displayName,
						hexcode: `#${team.hexcode}`,
						logo: team.logo,
						abbreviation: team.abbreviation,
						integrations: team.integrations,
						isEliminated: false,
						useAbbreviation: false
					} as ITeam;

					this.teams.push(this.createTeamGroup(newTeam));
				});

				const teams = this.teams.value as ITeam[];

				result.games.forEach((game) => {
					const team1 = teams.find(
						(t) => t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id === game.homeTeamIntegrationId
					);
					const team1Score = game.homeTeamScore;
					const team2 = teams.find(
						(t) => t.integrations.find((x) => x.type === SportsDBIntegrationKey)?.id === game.awayTeamIntegrationId
					);
					const team2Score = game.awayTeamScore;

					let winnerId = undefined;
					if (game.status === "Final") {
						if (team1 && team1Score > team2Score) {
							winnerId = team1.id;
						}

						if (team2 && team2Score > team1Score) {
							winnerId = team2.id;
						}
					}

					const newGame = {
						id: crypto.randomUUID(),
						startTime: game.startTime != null ? new Date(game.startTime) : null,
						integrationId: game.integrationId,
						status: game.status,
						winnerId: winnerId,
						tvProvider: game.tvProvider,
						highlightLink: undefined,
						name: game.name,
						team1Id: team1?.id,
						team1: team1,
						round: game.round,
						team1Score: team1Score,
						team2Id: team2?.id,
						team2: team2,
						team2Score: team2Score,
						displayOrder: undefined,
						eliminateLoser: false,
						seriesId: undefined
					} as IGame;

					this.games.push(this.createGameForm(newGame));
				});

				this.importing$.next(false);
			});
	}

	getRounds(): string[] {
		if (this.games) {
			return [...new Set(this.games.value.map((x) => x.round))];
		}

		return [];
	}

	getGamesByRound(round: string): IGame[] {
		return this.games.value.filter((x) => x.round === round && (this.showFinalGames.value || x.status !== "Final"));
	}

	getSeriesByRound(round: string): ISeries[] {
		return this.series.value.filter((x) => x.round === round && (this.showFinalGames.value || x.status !== "Final"));
	}

	editGame(game: IGame): void {
		this.dialog
			.open(GameEditDialogComponent, { data: { game: game, teams: this.teams.value, regions: this.regions.value } })
			.afterClosed()
			.pipe(
				take(1),
				filter((x) => !!x)
			)
			.subscribe((result) => {
				var gameIndex = this.games.value.findIndex((x) => x.id === result.id);
				if (result.deleteGame) {
					this.games.removeAt(gameIndex);
				} else {
					this.games.at(gameIndex).setValue(result);
				}
			});
	}

	editSeries(series: ISeries): void {
		this.dialog
			.open(SeriesEditDialogComponent, { data: { series: series, teams: this.teams.value, regions: this.regions.value } })
			.afterClosed()
			.pipe(
				take(1),
				filter((x) => !!x)
			)
			.subscribe((result) => {
				var seriesIndex = this.series.value.findIndex((x) => x.id === result.id);
				if (result.deleteGame) {
					this.series.removeAt(seriesIndex);
				} else {
					this.series.at(seriesIndex).setValue(result);
				}
			});
	}

	addEvent(): void {
		this.router.navigate(["admin", "league", this.leagueId, "event"]);
	}

	openEvent(eventId: string): void {
		this.router.navigate(["admin", "league", this.leagueId, "event", eventId]);
	}

	save(): void {
		this.saving$.next(true);

		const league = {
			...this.leagueForm.value
		} as ILeague;

		if (this.leagueId) {
			// Update
			this.leagueService.update(league).then(() => {
				this.router.navigate(["admin", "league", this.leagueId], { onSameUrlNavigation: "reload" });
				this.saving$.next(false);
			});
		} else {
			// Create
			league.id = crypto.randomUUID();
			this.leagueService.create(league).then((leagueId) => {
				this.router.navigate(["admin", "league", leagueId]);
				this.saving$.next(false);
			});
		}
	}

	getTeamById(teamId: string | undefined): ITeam | undefined {
		return this.teams.value.find((x: any) => x.id === teamId);
	}

	private createForm(league?: ILeague): void {
		this.leagueForm = this.fb.group({
			id: this.fb.control(league?.id),
			sport: this.fb.control(league?.sport),
			type: this.fb.control(league?.type),
			description: this.fb.control(league?.description),
			year: this.fb.control(league?.year),
			status: this.fb.control(league?.status),
			isPromotional: this.fb.control(league?.isPromotional),
			integrationData: this.fb.group({
				leagueId: this.fb.control(league?.integrationData.leagueId),
				seasonId: this.fb.control(league?.integrationData.seasonId)
			}),
			teams: this.fb.array(league?.teams.map((t) => this.createTeamGroup(t)) ?? []),
			games: this.fb.array(league?.games.map((g) => this.createGameForm(g)) ?? []),
			series: this.fb.array(league?.series?.map((s) => this.createSeriesForm(s)) ?? []),
			regions: this.fb.array(league?.regions?.map((r) => this.createRegionGroup(r)) ?? [])
		});

		this.integrationSeasons$ = this.integrationLeagueId.valueChanges.pipe(
			takeUntil(this.destroyed$),
			filter((x) => !!x),
			switchMap((integrationLeagueId) => this.integrationService.getSeasons(integrationLeagueId)),
			map((seasons) => seasons.sort((a, b) => compare(a, b, false)))
		);
	}

	private createRegionGroup(region?: IRegion): FormGroup {
		return this.fb.group({
			id: this.fb.control(region?.id || crypto.randomUUID()),
			name: this.fb.control(region?.name || ""),
			location: this.fb.control(region?.location || "")
		});
	}

	private createTeamGroup(team?: ITeam): FormGroup {
		return this.fb.group({
			id: this.fb.control(team?.id || crypto.randomUUID()),
			seed: this.fb.control(team?.seed || undefined),
			displayName: this.fb.control(team?.displayName || ""),
			division: this.fb.control(team?.division || ""),
			hexcode: this.fb.control(team?.hexcode || ""),
			logo: this.fb.control(team?.logo || ""),
			record: this.fb.control(team?.record || ""),
			abbreviation: this.fb.control(team?.abbreviation || ""),
			integrations: this.fb.array(team?.integrations.map((x) => this.createTeamIntegrationGroup(x)) || []),
			isEliminated: this.fb.control(team?.isEliminated || false),
			useAbbreviation: this.fb.control(team?.useAbbreviation || false)
		});
	}

	private createTeamIntegrationGroup(team: { id: string; type: string }): FormGroup {
		return this.fb.group({
			id: team.id,
			type: team.type
		});
	}

	private createGameForm(game?: IGame): FormGroup {
		return this.fb.group({
			id: this.fb.control(game?.id || crypto.randomUUID()),
			status: this.fb.control(game?.status || undefined),
			team1Id: this.fb.control(game?.team1Id || undefined),
			team2Id: this.fb.control(game?.team2Id || undefined),
			name: this.fb.control(game?.name || undefined),
			team1Score: this.fb.control(game?.team1Score || undefined),
			team2Score: this.fb.control(game?.team2Score || undefined),
			round: this.fb.control(game?.round || undefined),
			winnerId: this.fb.control(game?.winnerId || undefined),
			displayOrder: this.fb.control(game?.displayOrder || undefined),
			startTime: this.fb.control(game?.startTime || undefined),
			tvProvider: this.fb.control(game?.tvProvider || undefined),
			integrationId: this.fb.control(game?.integrationId || undefined),
			eliminateLoser: this.fb.control(game?.eliminateLoser || undefined),
			seriesId: this.fb.control(game?.seriesId || undefined),
			regionId: this.fb.control(game?.regionId || undefined),
			highlightLink: this.fb.control(game?.highlightLink || undefined)
		});
	}

	private createSeriesForm(series?: ISeries): FormGroup {
		return this.fb.group({
			id: this.fb.control(series?.id || crypto.randomUUID()),
			status: this.fb.control(series?.status || undefined),
			team1Id: this.fb.control(series?.team1Id || undefined),
			team2Id: this.fb.control(series?.team2Id || undefined),
			team1Score: this.fb.control(series?.team1Score || undefined),
			team2Score: this.fb.control(series?.team2Score || undefined),
			round: this.fb.control(series?.round || undefined),
			winnerId: this.fb.control(series?.winnerId || undefined),
			displayOrder: this.fb.control(series?.displayOrder || undefined),
			regionId: this.fb.control(series?.regionId || undefined),
			clinchingAmount: this.fb.control(series?.clinchingAmount || undefined),
			totalNumberOfGames: this.fb.control(series?.totalNumberOfGames || undefined)
		});
	}
}
