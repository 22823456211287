<button mat-button style="width: max-content" (click)="goToLogin()"><mat-icon>chevron_left</mat-icon>Return to Login</button>
<form [formGroup]="createAccount" class="signup-form" (submit)="createUserAccount()" *ngIf="!success; else successTemplate">
	<mat-form-field>
		<mat-label>Email</mat-label>
		<input matInput type="email" formControlName="email" autocomplete="email username" />
		<mat-error *ngIf="email.hasError('email') && !email.hasError('required')"> Please enter a valid email address </mat-error>
		<mat-error *ngIf="email.hasError('required')"> Email is <strong>required</strong> </mat-error>
	</mat-form-field>
	<mat-form-field>
		<mat-label>Display Name</mat-label>
		<input matInput formControlName="displayName" />
	</mat-form-field>
	<mat-form-field>
		<mat-label>Password</mat-label>
		<input matInput type="password" formControlName="password" autocomplete="new-password" />
		<mat-error *ngIf="email.hasError('email') && !email.hasError('format')"> Invalid formatting </mat-error>
		<mat-error *ngIf="email.hasError('required')"> Passsword is <strong>required</strong> </mat-error>
	</mat-form-field>
	<mat-form-field>
		<mat-label>Verify Password</mat-label>
		<input matInput type="password" formControlName="password2" autocomplete="new-password" />
	</mat-form-field>
	<mat-error *ngIf="createAccount.hasError('match_error')"> Passwords dont match </mat-error>
	<mat-error *ngIf="errorMessage">
		{{ errorMessage }}
	</mat-error>
	<div class="legal">
		By clicking Create Account or Sign in with Google, you agree to our
		<a routerLink="/terms-of-service" target="_blank">Terms of Service</a> and
		<a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>
	</div>
	<button mat-raised-button color="accent" [disabled]="!createAccount.valid || createAccount.pristine || updating">Create Account</button>
</form>

<ng-template #successTemplate>
	<h2 class="success-text">
		Account has been created. Please check your email, you will need to verify your email before you are able to login.
	</h2>
</ng-template>
