import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";

@Component({
	template: "",
	selector: "am-base-component"
})
export abstract class BaseComponent implements OnDestroy {
	protected destroyed$ = new Subject<boolean>();

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
