import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, filter, map, take, takeUntil } from "rxjs";
import { isLoggedIn, isMobile } from "src/app/state/app/app.selectors";
import { BaseComponent } from "src/app/shared/components/base.component";
import { loadEvents } from "src/app/state/event/event.actions";
import { IEvent } from "src/app/models/event";
import { animate, style, transition, trigger } from "@angular/animations";
import { loadPublicRoomsWithCapacity } from "src/app/state/group/group.actions";
import { CountdownPipe } from "../../shared/pipes/countdown.pipe";
import { FooterComponent } from "../../shared/components/footer/footer.component";
import { PublicRoomComponent } from "../../shared/components/public-room/public-room.component";
import { MatCard } from "@angular/material/card";
import { MatButton } from "@angular/material/button";
import { NgIf, AsyncPipe } from "@angular/common";

@Component({
	templateUrl: "./unauthenticated.component.html",
	styleUrls: ["./unauthenticated.component.scss"],
	animations: [
		trigger("fade", [
			transition(":enter", [
				style({ opacity: 0 }),
				animate(
					"500ms",
					style({
						opacity: 1
					})
				)
			])
		])
	],
	standalone: true,
	imports: [NgIf, MatButton, MatCard, PublicRoomComponent, FooterComponent, CountdownPipe, AsyncPipe]
})
export class UnauthenticatedComponent extends BaseComponent implements OnInit {
	isMobile$: Observable<boolean>;
	event$: Observable<IEvent>;

	innerWidth: number = window.innerWidth <= 500 ? window.innerWidth : 500;

	constructor(
		private store: Store,
		private router: Router
	) {
		super();
	}

	@HostListener("window:resize", ["$event"])
	onResize() {
		if (window.innerWidth <= 500) {
			this.innerWidth = window.innerWidth;
		}
	}

	ngOnInit(): void {
		// TODO: Rework this
		// this.event$ = this.store.select(events).pipe(
		// 	takeUntil(this.destroyed$),
		// 	map((events) => events.filter((event) => event.status === "Open" && event.isPromotional)[0])
		// );

		this.store.dispatch(loadEvents());

		this.store
			.select(isLoggedIn)
			.pipe(filter((loggedIn) => loggedIn))
			.pipe(take(1))
			.subscribe(() => this.router.navigate(["/home"]));

		this.isMobile$ = this.store.select(isMobile).pipe(takeUntil(this.destroyed$));

		this.event$.pipe(takeUntil(this.destroyed$)).subscribe((x: IEvent) => {
			this.store.dispatch(loadPublicRoomsWithCapacity({ eventId: x.id }));
		});
	}

	signup(): void {
		this.router.navigate(["login", "signup"]);
	}

	publicRoom(): void {
		window.location.href = "#public-rooms";
	}
}
