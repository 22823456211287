import { createAction, props } from "@ngrx/store";
import { IGroup } from "src/app/models/group";
import { IGroupInvite } from "src/app/models/invite";

export const loadGroup = createAction("[Group] Load Group", props<{ eventId: string; groupId: string }>());
export const loadGroupSuccess = createAction("[Group] Load Group Success", props<{ group: IGroup }>());
export const loadGroupError = createAction("[Group] Load Group Error", props<{ errorMessage: string }>());

export const loadGroups = createAction("[Group] Load Groups");
export const loadGroupsSuccess = createAction("[Group] Load Groups Success", props<{ groups: IGroup[] }>());
export const loadGroupsError = createAction("[Group] Load Groups Error", props<{ errorMessage: string }>());

export const setSelectedGroup = createAction("[Group] Group Selected", props<{ eventId: string; groupId: string }>());
export const setSelectedGroupSuccess = createAction("[Group] Group Selected Success", props<{ groupId: string }>());
export const setSelectedGroupError = createAction("[Group] Group Selected Error", props<{ errorMessage: string }>());

export const acceptGroupInvite = createAction("[Group] Accept Group Invite", props<{ invite: IGroupInvite; eventType: string }>());
export const acceptGroupInviteSuccess = createAction("[Group] Accept Group Invite Success");
export const acceptGroupInviteError = createAction("[Group] Accept Group Invite Error", props<{ errorMessage: string }>());

export const leaveGroup = createAction("[Group] Leave Group", props<{ eventId: string; groupId: string }>());
export const leaveGroupSuccess = createAction("[Group] Leave Group Success", props<{ groupId: string }>());
export const leaveGroupError = createAction("[Group] Leave Group Error", props<{ errorMessage: string }>());

export const loadPublicRoomsWithCapacity = createAction("[Group] Load Public Rooms with Capacity", props<{ eventId: string }>());
export const upsertPublicRoomsWithCapacity = createAction("[Group] Upsert Public Rooms with Capacity", props<{ groups: IGroup[] }>());
export const loadPublicRoomsWithCapacitySuccess = createAction("[Group] Load Public Rooms with Capacity Success");
export const loadPublicRoomsWithCapacityError = createAction(
	"[Group] Load Public Rooms with Capacity Failed",
	props<{ errorMessage: string }>()
);

export const clearGroups = createAction("[Group] Clear Groups");

export const getGroupsDataCached = createAction("[Group] Cache Hit On Group Data");
