<ng-container *ngIf="isMobile$ | async; else desktop">
	<div class="tutorial mobile">
		<h2>How to play</h2>
		<div *ngFor="let item of items">
			<div class="title mobile-title">
				<mat-icon>{{ item.icon }}</mat-icon>
				<div>{{ item.title }}</div>
			</div>
			<div class="message">{{ item.message }}</div>
		</div>
		<mat-dialog-actions>
			<mat-checkbox [formControl]="showMeAgain" color="primary">Don't show me again</mat-checkbox>
			<button mat-stroked-button color="warn" (click)="dialogRef.close(showMeAgain.value)">Close</button>
		</mat-dialog-actions>
	</div>
</ng-container>

<ng-template #desktop>
	<div class="tutorial">
		<h2>How to play</h2>
		<div *ngFor="let item of items" class="list-item">
			<mat-icon>{{ item.icon }}</mat-icon>
			<div class="title">
				{{ item.title }}
			</div>
			<div class="message">{{ item.message }}</div>
		</div>
		<mat-dialog-actions>
			<mat-checkbox [formControl]="showMeAgain" color="primary">Don't show me again</mat-checkbox>
			<button mat-stroked-button color="warn" (click)="dialogRef.close(showMeAgain.value)">Close</button>
		</mat-dialog-actions>
	</div>
</ng-template>
