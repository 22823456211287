import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { IPrivate, IUser } from "src/app/models/user";

export const getUserData = createAction("[User] Load User Data", props<{ userId: string }>());
export const getUserDataSuccess = createAction("[User] Load User Data Success", props<{ user: IUser }>());
export const getUserDataError = createAction("[User] Load User Data Error", props<{ errorMessage: string }>());

export const getUsersData = createAction("[User] Load Users Data", props<{ userIds: string[] }>());
export const getUsersDataSuccess = createAction("[User] Load Users Data Success", props<{ usersData: IUser[] }>());
export const getUsersDataError = createAction("[User] Load Users Data Error", props<{ errorMessage: string }>());

export const getUsersDataCached = createAction("[User] Cache Hit On User Data");
