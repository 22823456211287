<ng-container>
	<h2 mat-dialog-title>
		{{title}}
	</h2>
	<mat-dialog-content>
		{{text}}
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-stroked-button color="warn" (click)="close()">Cancel</button>
		<button mat-raised-button class="left" color="accent" (click)="confirm()">Confirm</button>
	</mat-dialog-actions>
</ng-container>
