import { Action, createReducer, on } from "@ngrx/store";
import * as app from "./app.actions";

export interface AppState {
	currentUID: string;
	checkedAuth: boolean;
	currentBreakPoint: string;
	showEliminated: boolean;
	roles: string[];
	configs: {
		[key: string]: string | number | boolean;
	};
}

export const InitialState: AppState = {
	currentUID: "",
	checkedAuth: false,
	currentBreakPoint: "",
	showEliminated: false,
	roles: [],
	configs: {}
};

const reducer = createReducer(
	InitialState,
	on(app.checkLogin, (state) => ({ ...state, checkedAuth: false })),
	on(app.updateCurrentUser, (state, { uid, claims }) => ({ ...state, currentUID: uid, roles: claims?.roles || [], checkedAuth: true })),
	on(app.updateCurrentBreakPoint, (state, { currentBreakPoint }) => ({ ...state, currentBreakPoint: currentBreakPoint })),
	on(app.updateShowEliminated, (state, { showEliminated }) => ({ ...state, showEliminated: showEliminated })),
	on(app.setConfigs, (state, { configs }) => ({ ...state, configs: configs })),
	on(app.logout, (state) => ({ ...state, currentUID: "", roles: [], showEliminated: false }))
);

export function appReducers(state: AppState | undefined, action: Action) {
	return reducer(state, action);
}
