import { createSelector, createFeatureSelector } from "@ngrx/store";
import * as fromProfile from "./profile.reducers";

export const userState = createFeatureSelector<fromProfile.ProfileState>("profile");

export const privateInfoLoaded = createSelector(userState, (state) => state.privateInfoLoaded);

export const privateUserInfo = createSelector(userState, (state) => state.privateUserInfo);

export const updating = createSelector(userState, (state) => state.updating);
