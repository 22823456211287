<mat-dialog-content>
	<div class="header">
		<h3>{{ data.group.name }} Score Audit</h3>
		<mat-form-field>
			<mat-label>Team Name</mat-label>
			<input matInput [formControl]="teamNameControl" />
		</mat-form-field>
	</div>
	<ng-container *ngIf="filteredGroupAudit$ | async as groupAudits">
		<table mat-table [dataSource]="groupAudits">
			<ng-container matColumnDef="time">
				<th mat-header-cell *matHeaderCellDef>Time</th>
				<td mat-cell *matCellDef="let element">
					{{ element.time | date : "short" }}
				</td>
			</ng-container>
			<ng-container matColumnDef="team">
				<th mat-header-cell *matHeaderCellDef>Team</th>
				<td mat-cell *matCellDef="let element">
					{{ getTeamNameByID(element.teamId) }}
				</td>
			</ng-container>
			<ng-container matColumnDef="user">
				<th mat-header-cell *matHeaderCellDef>User</th>
				<td mat-cell *matCellDef="let element">
					{{ getOwnerByTeam(element.teamId) }}
				</td>
			</ng-container>

			<ng-container matColumnDef="amount">
				<th mat-header-cell *matHeaderCellDef>Amount</th>
				<td mat-cell *matCellDef="let element">
					{{ element.amount | currency : "USD" : "symbol" : "1.2-2" }}
				</td>
			</ng-container>
			<ng-container matColumnDef="updated">
				<th mat-header-cell *matHeaderCellDef>Updated to Score</th>
				<td mat-cell *matCellDef="let element">
					{{ element.updatedScore | currency : "USD" : "symbol" : "1.2-2" }}
				</td>
			</ng-container>
			<ng-container matColumnDef="game">
				<th mat-header-cell *matHeaderCellDef>Game</th>
				<td mat-cell *matCellDef="let element">
					{{ element.gameId }}
				</td>
			</ng-container>
			<ng-container matColumnDef="tier">
				<th mat-header-cell *matHeaderCellDef>Tier</th>
				<td mat-cell *matCellDef="let element">
					{{ element.tier }}
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="columns"></tr>
			<tr mat-row *matRowDef="let element; columns: columns"></tr>
		</table>
	</ng-container>
</mat-dialog-content>

<mat-dialog-actions>
	<button color="error" mat-stroked-button (click)="dialogRef.close()">Close</button>
</mat-dialog-actions>
