<div class="page">
	<button mat-button class="home-button" (click)="goToHomepage()"><mat-icon>chevron_left</mat-icon>Home</button>
	<div class="login">
		<div class="logo" *ngIf="!(smallScreen$ | async)">
			<img width="300" [src]="'../assets/icons/logos/generic.svg'" />
		</div>
		<mat-divider vertical *ngIf="!(smallScreen$ | async)"></mat-divider>
		<div class="content">
			<ng-container *ngIf="mode$ | async as mode; else regularRoutes">
				<am-confirm-email *ngIf="mode === 'verifyEmail'"></am-confirm-email>
				<am-confirm-password-reset *ngIf="mode === 'resetPassword'"></am-confirm-password-reset>
			</ng-container>

			<ng-template #regularRoutes>
				<router-outlet (activate)="passFunctionToChild($event)"></router-outlet>
			</ng-template>
			<mat-divider></mat-divider>

			<div class="login-provider-list">
				<button mat-raised-button class="google-button" (click)="loginWithGoogle()">
					<div class="button-label">
						<img width="18" src="../assets/img/auth-provider-logos/google.svg" /><span>Sign in with Google</span>
					</div>
				</button>
				<button mat-raised-button class="apple-button" (click)="loginWithApple()">
					<div class="button-label">
						<img width="18" src="../assets/img/auth-provider-logos/apple-black.svg" /><span>Sign in with Apple</span>
					</div>
				</button>
				<button mat-raised-button class="facebook-button" *ngIf="this.showFacebookLogin | async" (click)="loginWithFacebook()">
					<div class="button-label">
						<img width="18" src="../assets/img/auth-provider-logos/facebook.svg" /><span>Sign in with Facebook</span>
					</div>
				</button>
			</div>
		</div>
	</div>
</div>
<am-footer></am-footer>
