<button mat-button (click)="goToLogin()"><mat-icon>chevron_left</mat-icon>Return to Login</button>
<div *ngIf="status === 'Checking'">
    <h3>Verifying Email!</h3>
</div>
<div *ngIf="status === 'Error'">
    <h3>Error Occurred</h3>
    <span>{{ errorCode }}</span>
</div>
<div *ngIf="status === 'Success'">
    <h3>Successfully verified your email!</h3>
    <button mat-button (click)="goToLogin()">Go to Login</button>
</div>
