import { createSelector, createFeatureSelector } from "@ngrx/store";
import { AppState } from "./app.reducers";
import { BreakPointDisplayNameMap } from "src/app/models/constants";
import { Breakpoints } from "@angular/cdk/layout";

export const appInfoStateSelector = createFeatureSelector<AppState>("app");

export const currentUID = createSelector(appInfoStateSelector, (state) => state.currentUID || "");

export const checkedAuth = createSelector(appInfoStateSelector, (state) => state.checkedAuth);

export const isLoggedIn = createSelector(
	appInfoStateSelector,
	(state) => !!state.currentUID && state.checkedAuth && state.currentUID !== ""
);

export const showEliminated = createSelector(appInfoStateSelector, (state) => state.showEliminated);

export const userRoles = createSelector(appInfoStateSelector, (state) => state.roles);

export const currentBreakPoint = createSelector(appInfoStateSelector, (state) => state.currentBreakPoint);

export const isMobile = createSelector(currentBreakPoint, (breakpoint) => {
	if (breakpoint === BreakPointDisplayNameMap.get(Breakpoints.XSmall)) {
		return true;
	} else {
		return false;
	}
});

export const configs = createSelector(appInfoStateSelector, (state) => state.configs);

export const getConfig = (configName: string) => createSelector(configs, (configs) => configs[configName]);
export const getConfigAsBoolean = (configName: string) => createSelector(getConfig(configName), (config) => config === true);
