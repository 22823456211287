<form *ngIf="leagueForm" [formGroup]="leagueForm" class="league-form">
	<div class="header">
		<h2>League: {{ year.value }} {{ sport.value }}</h2>
		<am-loading-button title="Save" color="primary" [loading]="saving$ | async" (clicked)="save()"></am-loading-button>
	</div>
	<div class="section">
		<mat-form-field appearance="outline">
			<mat-label>Sport</mat-label>
			<mat-select formControlName="sport">
				@for (item of sportOptions; track $index) {
					<mat-option [value]="item">
						{{ item }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Type</mat-label>
			<mat-select formControlName="type">
				@for (item of typeOptions; track $index) {
					<mat-option [value]="item">
						{{ item }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Year</mat-label>
			<input matInput type="number" formControlName="year" />
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Status</mat-label>
			<mat-select formControlName="status">
				@for (item of statusOptions; track $index) {
					<mat-option [value]="item">
						{{ item }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>
		<mat-checkbox formControlName="isPromotional">Is Promotional</mat-checkbox>
		<mat-form-field appearance="outline">
			<mat-label>Description</mat-label>
			<textarea matInput rows="1" formControlName="description"></textarea>
		</mat-form-field>
	</div>

	<h4>Integration Data</h4>
	<div class="section" [formGroup]="integrationData">
		<mat-form-field appearance="outline">
			<mat-label>League</mat-label>
			<mat-select formControlName="leagueId">
				@for (league of integrationLeagues$ | async; track $index) {
					<mat-option [value]="league.integrationId">
						{{ league.name }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>
		@if (integrationSeasons$ | async; as integrationSeasons) {
			<mat-form-field appearance="outline">
				<mat-label>Season</mat-label>
				<mat-select formControlName="seasonId">
					@for (season of integrationSeasons; track $index) {
						<mat-option [value]="season">
							{{ season }}
						</mat-option>
					}
				</mat-select>
			</mat-form-field>
		}
	</div>
	<button color="primary" type="button" mat-raised-button [disabled]="!canImport" (click)="import()">Import</button>

	<mat-tab-group>
		<mat-tab label="Teams">
			<div class="teams">
				<button class="add-button" mat-stroked-button (click)="addTeam()">Add Team</button>

				<table mat-table [dataSource]="teams.controls | keyvalue">
					<ng-container cdkColumnDef="seed">
						<mat-header-cell *cdkHeaderCellDef> Seed </mat-header-cell>
						<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
							<input matInput type="text" formControlName="seed" />
						</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="displayName">
						<mat-header-cell *cdkHeaderCellDef> Display Name </mat-header-cell>
						<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
							<input matInput type="text" formControlName="displayName" />
						</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="abbreviation">
						<mat-header-cell *cdkHeaderCellDef> Abbreviation </mat-header-cell>
						<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
							<input matInput type="text" formControlName="abbreviation" />
						</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="record">
						<mat-header-cell *cdkHeaderCellDef> Record </mat-header-cell>
						<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
							<input matInput type="text" formControlName="record" />
						</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="division">
						<mat-header-cell *cdkHeaderCellDef> Division </mat-header-cell>
						<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
							<input matInput type="text" formControlName="division" />
						</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="hexcode">
						<mat-header-cell *cdkHeaderCellDef> Hexcode </mat-header-cell>
						<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
							<input
								matInput
								type="text"
								formControlName="hexcode"
								class="hexcode-cell"
								[style]="'background-color: ' + element.value.get('hexcode')?.value"
							/>
						</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="logo">
						<mat-header-cell *cdkHeaderCellDef> Logo </mat-header-cell>
						<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
							<img class="logo-cell" [src]="element.value.get('logo')?.value" />
							<input matInput type="text" formControlName="logo" />
						</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="isEliminated">
						<mat-header-cell *cdkHeaderCellDef> Eliminated </mat-header-cell>
						<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
							<mat-checkbox style="margin: auto" formControlName="isEliminated" color="primary"></mat-checkbox>
						</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="useAbbreviation">
						<mat-header-cell *cdkHeaderCellDef> Use Abbreviation </mat-header-cell>
						<mat-cell *cdkCellDef="let element" [formGroup]="element.value">
							<mat-checkbox style="margin: auto" formControlName="useAbbreviation" color="primary"></mat-checkbox>
						</mat-cell>
					</ng-container>
					<ng-container cdkColumnDef="actions">
						<mat-header-cell *cdkHeaderCellDef> Actions </mat-header-cell>
						<mat-cell *cdkCellDef="let element; let i = index">
							<button mat-button color="warn" (click)="deleteTeam(i)">Delete</button>
						</mat-cell>
					</ng-container>

					<mat-header-row *matHeaderRowDef="teamColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: teamColumns"></mat-row>
				</table>
			</div>
		</mat-tab>
		<mat-tab label="Games">
			<div class="tab-header">
				<button class="add-button" mat-stroked-button (click)="addGame()">Add Game</button>
				<mat-checkbox [formControl]="showFinalGames">Show Final</mat-checkbox>
			</div>

			<mat-accordion>
				@for (round of getRounds(); track round) {
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title> Round: {{ round }} </mat-panel-title>
						</mat-expansion-panel-header>
						<div class="games">
							@for (game of getGamesByRound(round); track game) {
								<mat-card (click)="editGame(game)">
									<mat-card-content>
										{{ getTeamById(game.team1Id)?.displayName }}: {{ game.team1Score }} <br />
										{{ getTeamById(game.team2Id)?.displayName }}: {{ game.team2Score }} <br />
										{{ game.startTime != null ? (game.startTime | date: "short") : "" }} <br />
										{{ game.status }}
									</mat-card-content>
								</mat-card>
							}
						</div>
					</mat-expansion-panel>
				}
			</mat-accordion>
		</mat-tab>

		<mat-tab label="Series">
			<button class="add-button" mat-stroked-button (click)="addSeries()">Add Series</button>
			<mat-accordion>
				@for (round of getRounds(); track round) {
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title> Round: {{ round }} </mat-panel-title>
						</mat-expansion-panel-header>
						<div class="games">
							@for (series of getSeriesByRound(round); track series) {
								<mat-card (click)="editSeries(series)">
									<mat-card-content>
										{{ getTeamById(series.team1Id)?.displayName }}: {{ series.team1Score }} <br />
										{{ getTeamById(series.team2Id)?.displayName }}: {{ series.team2Score }} <br />
										{{ series.status }}
									</mat-card-content>
								</mat-card>
							}
						</div>
					</mat-expansion-panel>
				}
			</mat-accordion>
		</mat-tab>

		<mat-tab label="Regions">
			<button class="add-button" mat-stroked-button (click)="addRegion()">Add Region</button>
			<div class="regions">
				@for (region of regions.controls; track index; let index = $index) {
					<mat-card [formGroup]="region" class="region-card">
						<mat-form-field appearance="outline">
							<mat-label>Name</mat-label>
							<input matInput formControlName="name" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Location</mat-label>
							<input matInput formControlName="location" />
						</mat-form-field>
						<mat-card-actions>
							<button mat-stroked-button color="warn" (click)="deleteRegion(index)">Delete</button>
						</mat-card-actions>
					</mat-card>
				}
			</div>
		</mat-tab>

		@if (events$ | async; as events) {
			<mat-tab label="Events">
				<div class="events">
					<button class="add-button" mat-stroked-button (click)="addEvent()">Add Event</button>
					@for (event of events; track $index) {
						<mat-card>
							<mat-card-header>
								<mat-card-title>{{ event.name }}</mat-card-title>
							</mat-card-header>
							<mat-card-content>
								<p>{{ event.minAuctionDatetime | date: "short" }} - {{ event.maxAuctionDatetime | date: "short" }}</p>
								<p>Status: {{ event.status }}</p>
								<mat-divider></mat-divider>
							</mat-card-content>
							<mat-card-actions>
								<button mat-button (click)="openEvent(event.id)">Edit</button>
							</mat-card-actions>
						</mat-card>
					}
				</div>
			</mat-tab>
		}
	</mat-tab-group>
</form>
