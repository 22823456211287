import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { switchMap, map, filter, tap, withLatestFrom, catchError } from "rxjs/operators";
import * as appActions from "./app.actions";
import * as userActions from "../user/user.actions";
import * as groupActions from "../group/group.actions";
import * as userEventGroupActions from "../user-event-group/user-event-group.action";
import { Router } from "@angular/router";
import { from, of } from "rxjs";
import * as eventActions from "../event/event.actions";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ConfigService } from "src/app/services/config.service";
import { isLoggedIn } from "./app.selectors";
import { Store } from "@ngrx/store";
import { getPrivateUserData } from "../profile/profile.actions";
import { AnalyticsService } from "src/app/services/analytics.service";

@Injectable()
export class AppEffects {
	constructor(
		private actions$: Actions,
		private auth: AuthenticationService,
		private router: Router,
		private configService: ConfigService,
		private store: Store,
		private analyticService: AnalyticsService
	) {}

	checkLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(appActions.checkLogin),
			switchMap((action) =>
				this.auth.currentUser$.pipe(
					switchMap(async (user) => {
						if (user?.uid) {
							let token = await user.getIdTokenResult();
							return appActions.updateCurrentUser({
								uid: user.uid,
								claims: token?.claims as { roles: string[] }
							});
						} else {
							return appActions.updateCurrentUser({ uid: "", claims: { roles: [] } });
						}
					})
				)
			)
		)
	);

	logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(appActions.logout),
			switchMap(() =>
				from(this.auth.signOut()).pipe(
					tap(() => this.router.navigate([""])),
					switchMap(() => [
						appActions.logoutSuccess(),
						groupActions.clearGroups(),
						groupActions.setSelectedGroup({ groupId: "", eventId: "" })
					])
				)
			)
		)
	);

	getCurrentUserData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(appActions.updateCurrentUser),
			withLatestFrom(this.store.select(isLoggedIn)),
			filter(([action, isLoggedIn]) => !!action?.uid && isLoggedIn),
			tap(async ([action]) => await this.analyticService.setUserID(action.uid)),
			switchMap(([action, isLoggedIn]) => [
				userActions.getUserData({ userId: action.uid }),
				userEventGroupActions.getUserEventGroup({ uid: action.uid }),
				getPrivateUserData({ uid: action.uid })
			])
		)
	);

	getEventList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(appActions.updateCurrentUser),
			filter((uid) => !!uid),
			map(() => eventActions.loadEvents())
		)
	);

	getConfigs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(appActions.getConfigs),
			switchMap(() =>
				this.configService.getConfig().pipe(
					map((configs) => {
						let newConfigs: {
							[key: string]: string | number | boolean;
						} = {};

						for (const [key, value] of Object.entries(configs)) {
							newConfigs[key] = JSON.parse(value.asString());
						}

						return appActions.setConfigs({ configs: newConfigs });
					})
				)
			)
		)
	);
}
