import { CdkCellDef, CdkColumnDef, CdkHeaderCellDef } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { Component, computed, inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { ActivatedRoute, Router } from "@angular/router";
import { format, parseISO } from "date-fns";
import { take } from "rxjs";
import { IEvent, IPhase, IPhaseScoring, IPublicRoom, IScoringPreset, ITier } from "src/app/models/event";
import { IGame } from "src/app/models/game";
import { ILeague } from "src/app/models/league";
import { ITeam } from "src/app/models/team";
import { EventService } from "src/app/services/event.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { IPhaseScoringOverview, ScoringOverviewComponent } from "src/app/shared/components/scoring-overview/scoring-overview.component";
import { LeagueStore } from "src/app/state/league.store";

@Component({
	selector: "am-league-event-admin",
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatTabsModule,
		MatExpansionModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDividerModule,
		MatCardModule,
		MatTableModule,
		CdkColumnDef,
		CdkHeaderCellDef,
		CdkCellDef,
		ScoringOverviewComponent
	],
	templateUrl: "./league-event-admin.component.html",
	styleUrl: "./league-event-admin.component.scss"
})
export class LeagueEventAdminComponent extends BaseComponent implements OnInit {
	readonly leagueStore = inject(LeagueStore);

	isCreate: boolean = false;
	leagueId: string;
	eventId: string;
	form: FormGroup;

	league = this.leagueStore.currentLeague;
	rounds = computed(() => {
		const league = this.league();

		if (league) {
			return new Set<string>(league.games.map((x) => x.round));
		}

		return null;
	});

	gameColumns = ["name", "startTime", "status", "tier"];

	get phases(): FormArray<FormGroup> {
		return this.form.get("phases") as FormArray<FormGroup>;
	}

	get tiers(): FormArray<FormGroup> {
		return this.form.get("tiers") as FormArray<FormGroup>;
	}

	get scoringPresets() {
		return this.form.get(["scoringPresets"]) as FormArray<FormGroup>;
	}

	get bots() {
		return this.form.get(["bots"]) as FormArray<FormArray>;
	}

	get valuePicks() {
		return this.form.get(["valuePicks"]) as FormArray<FormGroup>;
	}

	get includedTeams() {
		return this.form.get(["includedTeams"]) as FormArray<FormControl<string>>;
	}

	phaseScoringGroups(scoringIndex: number): FormArray<FormGroup> {
		return this.form.get(["scoringPresets", scoringIndex, "phaseScorings"]) as FormArray<FormGroup>;
	}

	phaseScoringGroup(scoringIndex: number, phaseScoringIndex: number): FormGroup {
		return this.phaseScoringGroups(scoringIndex).get([phaseScoringIndex]) as FormGroup;
	}

	getScoringTiers(scoringIndex: number, phaseScoringIndex: number) {
		return this.phaseScoringGroup(scoringIndex, phaseScoringIndex).get(["tiers"]) as FormArray<FormGroup>;
	}

	getTierRound(tierIndex: number): FormArray<FormControl<string>> {
		return this.tiers.at(tierIndex).get("rounds") as FormArray;
	}

	constructor(
		private fb: FormBuilder,
		private eventService: EventService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private snackbarService: SnackbarService
	) {
		super();
	}

	ngOnInit(): void {
		this.leagueId = this.activatedRoute.snapshot.params["leagueId"];
		this.eventId = this.activatedRoute.snapshot.params["eventId"];

		this.leagueStore.setCurrentLeague(this.leagueId);

		if (this.eventId) {
			this.eventService
				.getByID(this.eventId)
				.pipe(take(1))
				.subscribe((event) => this.createForm(event));
		} else {
			this.isCreate = true;
			this.createForm();
		}
	}

	addPhase(): void {
		this.phases.push(this.createPhaseGroup());
	}

	deletePhase(index: number): void {
		this.phases.removeAt(index);
	}

	addTier(): void {
		this.tiers.push(this.createTierGroup());
	}

	deleteTier(index: number): void {
		this.tiers.removeAt(index);
	}

	createScoringPreset(): void {
		let scoringPreset = this.createScoringPresetGroup({
			id: crypto.randomUUID(),
			name: "",
			sortOrder: -1,
			isDefault: false,
			buyIn: 0,
			phaseScorings: []
		});
		this.scoringPresets.push(scoringPreset);
	}

	deleteScoringPreset(index: number): void {
		this.scoringPresets.removeAt(index);
	}

	getPrievewScoring(event: IEvent, index: number): IPhaseScoringOverview[] {
		const scoring = event.scoringPresets[index];
		const totalPot = event.defaultManagers * scoring.buyIn;

		const scoringOverview: IPhaseScoringOverview[] = scoring.phaseScorings
			.filter((x) => !!x.phaseId)
			.map((phaseScoring) => {
				const phase = event.phases.find((x) => x.id === phaseScoring.phaseId);

				if (!phase) {
					throw new Error(`Missing Phase ${phaseScoring.phaseId}`);
				}

				return {
					name: phase.name,
					amountForAllTiers: phaseScoring.amountForAllTiers ?? 0,
					amountToMakePhase: phaseScoring.amountToMakePhase ? phaseScoring.amountToMakePhase * totalPot : 0,
					tiers: phaseScoring.tiers
						.filter((x) => !!x.tierId)
						.map((tierScoring) => {
							const tier = event.tiers.find((x) => x.id === tierScoring.tierId);

							if (!tier) {
								throw new Error(`Missing Tier ${tierScoring.tierId}`);
							}

							return {
								name: tier?.name,
								amountForTier: tierScoring.defaultValue * totalPot
							};
						})
				};
			});

		return scoringOverview;
	}

	addPhaseScoring(scoringIndex: number): void {
		this.phaseScoringGroups(scoringIndex).push(this.createPhaseScoringPreset());
	}

	removePhaseScoring(scoringIndex: number, phaseScoringIndex: number): void {
		this.phaseScoringGroups(scoringIndex).removeAt(phaseScoringIndex);
	}

	addScoringTier(scoringIndex: number, phaseScoringIndex: number): void {
		this.getScoringTiers(scoringIndex, phaseScoringIndex).push(this.createScoringTierGroup());
	}

	createScoringTierGroup(): FormGroup {
		return this.fb.group({
			tierId: this.fb.control(undefined),
			defaultValue: this.fb.control(0)
		});
	}

	removeScoringTier(scoringIndex: number, phaseScoringIndex: number, scoringTierIndex: number): void {
		this.getScoringTiers(scoringIndex, phaseScoringIndex).removeAt(scoringTierIndex);
	}

	getRounds(games: IGame[]): string[] {
		if (games) {
			return [...new Set(games.map((x) => x.round))];
		}

		return [];
	}

	getGamesByRound(round: string, games: IGame[]): IGame[] {
		return games.filter((x) => x.round === round);
	}

	createBot(teams: ITeam[]) {
		const map = new Map<string, number>();

		Object.keys(teams).forEach((_, index) => {
			const teamId = teams.at(index)?.id;
			map.set(String(teamId), 0);
		});

		this.bots.push(this.createBotGroup(map));
	}

	removeBot(botIndex: number) {
		this.bots.removeAt(botIndex);
	}

	eventTeams(league: ILeague): ITeam[] {
		return league.teams.filter((x) => this.includedTeams.value.includes(x.id));
	}

	botValue(bot: any, teamId: string): any {
		return bot.value.find((x: { teamId: string; value: number }) => x.teamId === teamId)?.value ?? 0;
	}

	updateBotValue(value: any, teamId: string, botIndex: number): void {
		Object.keys(this.bots.at(botIndex).controls).forEach((x: string) => {
			const bot = this.bots.at(botIndex).get(x);

			if (bot && bot.value.teamId === teamId) {
				bot.get("value")?.setValue(Number(value.target.value));

				this.form.markAsDirty();
				return;
			}
		});
	}

	updatePickValue(value: any, teamId: string): void {
		const index = this.valuePicks?.value?.findIndex((x) => x.teamId === teamId);

		this.valuePicks?.at(index).get("value")?.setValue(Number(value.target.value));
	}

	picksValue(teamId: string): any {
		return this.valuePicks?.value?.find((x) => x.teamId === teamId)?.value ?? 0;
	}

	includeAllTeams(teams: ITeam[]): void {
		const addAll = this.includedTeams.length !== teams.length;

		while (this.includedTeams.length !== 0) {
			this.includedTeams.removeAt(0);
		}

		if (addAll) {
			teams.forEach((x) => {
				this.includedTeams.push(this.fb.control(x.id) as FormControl<string>);
			});
		}
	}

	updateIncludeTeam(teamId: string): void {
		const teamIndex = this.includedTeams.value.findIndex((x) => x === teamId);

		if (teamIndex > 0) {
			this.includedTeams.removeAt(teamIndex);
		} else {
			this.includedTeams.push(this.fb.control(teamId) as FormControl<string>);
		}
	}

	updateRound(tierIndex: number, round: string): void {
		const tierRounds = this.getTierRound(tierIndex);

		const roundIndex = tierRounds.value.findIndex((x) => x === round);

		if (roundIndex === -1) {
			tierRounds.push(this.fb.control(round) as FormControl<string>);
		} else {
			tierRounds.removeAt(roundIndex);
		}
	}

	save(): void {
		const event = {
			...this.form.value,
			scoringPresets: this.form.value.scoringPresets,
			type: this.league()?.type,
			leagueId: this.leagueId,
			publicRooms:
				this.form.value.publicRooms?.map((room: any) => {
					return {
						...room,
						auctionDate: parseISO(room.auctionDate)
					};
				}) ?? [],
			minAuctionDatetime: new Date(this.form.value.minAuctionDatetime),
			maxAuctionDatetime: new Date(this.form.value.maxAuctionDatetime)
		} as IEvent;

		event.bots =
			(event.bots as any)?.map((x: any[]) => {
				const map = new Map<string, number>();
				x.forEach((bot) => {
					map.set(bot.teamId, bot.value);
				});

				return map;
			}) ?? undefined;

		const valuePickMap = new Map<string, number>();
		(event.valuePicks as any).forEach((pick: any) => {
			valuePickMap.set(pick.teamId, pick.value);
		});

		event.valuePicks = valuePickMap ?? undefined;

		if (this.isCreate) {
			this.eventService.createEvent(event).then((eventId) => {
				this.router.navigate(["admin", "league", this.leagueId, "event", eventId]);
				this.snackbarService.success("Successfully created the event");
			});
		} else {
			this.eventService.updateEvent(event).then(() => {
				this.router.navigate(["admin", "league", this.leagueId, "event", this.eventId], { onSameUrlNavigation: "reload" });
				this.snackbarService.success("Successfully updated the event");
			});
		}
	}

	private createForm(event?: IEvent): void {
		this.form = this.fb.group({
			id: this.fb.control(event?.id || crypto.randomUUID()),
			name: this.fb.control(event?.name || null),
			minAuctionDatetime: this.fb.control(
				event?.minAuctionDatetime ? format(event.minAuctionDatetime, "yyyy-MM-dd HH:mm") : undefined
			),
			maxAuctionDatetime: this.fb.control(
				event?.maxAuctionDatetime ? format(event.maxAuctionDatetime, "yyyy-MM-dd HH:mm") : undefined
			),
			defaultManagers: this.fb.control(event?.defaultManagers),
			status: this.fb.control(event?.status),
			tiers: this.fb.array(event?.tiers.map((t) => this.createTierGroup(t)) || []),
			phases: this.fb.array(event?.phases?.sort((a, b) => a.order - b.order).map((p) => this.createPhaseGroup(p)) || []),
			publicRooms: this.fb.array(event?.publicRooms?.map((p) => this.createPublicRoomGroup(p)) || []),
			scoringPresets: this.fb.array(event?.scoringPresets?.map((s) => this.createScoringPresetGroup(s)) || []),
			bots: this.fb.array(event?.bots?.map((b) => this.createBotGroup(b)) || []),
			includedTeams: this.fb.array(event?.includedTeams?.map((x) => new FormControl<string>(x)) || []),
			isDefault: this.fb.control(event?.isDefault ?? false)
		});

		const league = this.league();

		if (event && !event?.valuePicks && league) {
			event.valuePicks = new Map<string, number>();

			this.eventTeams(league).forEach((team) => {
				event.valuePicks!!.set(team.id, 0);
			});
		}

		this.form.addControl("valuePicks", this.createPickGroup(event?.valuePicks!!));
	}

	private createTierGroup(tier?: ITier): FormGroup {
		return this.fb.group({
			id: this.fb.control(tier?.id || crypto.randomUUID()),
			name: this.fb.control(tier?.name || ""),
			phaseId: this.fb.control(tier?.phaseId || "", Validators.required),
			order: this.fb.control(tier?.order),
			rounds: this.fb.array(tier?.rounds?.filter((x) => this.rounds()?.has(x)).map((x) => this.fb.control<string>(x)) || [])
		});
	}

	private createPhaseGroup(phase?: IPhase): FormGroup {
		return this.fb.group({
			id: this.fb.control(phase?.id || crypto.randomUUID()),
			name: this.fb.control(phase?.name || ""),
			viewType: this.fb.control(phase?.viewType || null),
			numberOfTeamsInPhase: this.fb.control(phase?.numberOfTeamsInPhase || undefined),
			isSeriesBased: this.fb.control(phase?.isSeriesBased || false)
		});
	}

	private createPublicRoomGroup(publicRoom: IPublicRoom): FormGroup {
		const group = this.fb.group({
			id: this.fb.control(publicRoom?.id || ""),
			name: this.fb.control(publicRoom.name || "", Validators.required),
			numOfManagers: this.fb.control(publicRoom.numOfManagers || 0, Validators.required),
			auctionDate: this.fb.control(format(publicRoom.auctionDate, "yyyy-MM-dd HH:mm") || "", Validators.required),
			scoringPresetId: this.fb.control(publicRoom.scoringPresetId || undefined),
			groupId: this.fb.control(publicRoom.groupId || undefined),
			isFull: this.fb.control(publicRoom.isFull || false),
			isAutoRecreate: this.fb.control(publicRoom.isAutoRecreate || false)
		});

		group.controls["groupId"].disable();
		group.controls["isFull"].disable();

		if (publicRoom.isFull) {
			group.disable();
		}

		return group;
	}

	private createScoringPresetGroup(scoringPreset: IScoringPreset): FormGroup {
		return this.fb.group({
			id: this.fb.control(scoringPreset.id || crypto.randomUUID()),
			name: this.fb.control(scoringPreset.name || undefined),
			sortOrder: this.fb.control(scoringPreset.sortOrder || -1),
			isDefault: this.fb.control(scoringPreset.isDefault || false),
			buyIn: this.fb.control(scoringPreset.buyIn || 0),
			phaseScorings: this.fb.array(scoringPreset.phaseScorings.map((s) => this.createPhaseScoringPreset(s)))
		});
	}

	private createPhaseScoringPreset(phaseScoringPreset?: IPhaseScoring): FormGroup {
		return this.fb.group({
			phaseId: this.fb.control(phaseScoringPreset?.phaseId || undefined),
			amountToMakePhase: this.fb.control(phaseScoringPreset?.amountToMakePhase || undefined),
			amountForAllTiers: this.fb.control(phaseScoringPreset?.amountForAllTiers || undefined),
			dynamic: this.fb.control(phaseScoringPreset?.dynamic || undefined),
			tiers: this.fb.array(
				phaseScoringPreset?.tiers?.map((m) => {
					return this.fb.group({
						tierId: this.fb.control(m.tierId || undefined),
						defaultValue: this.fb.control(m.defaultValue || undefined)
					});
				}) || []
			)
		});
	}

	private createBotGroup(bot: Map<string, number>): FormArray {
		const botArray: any[] = [];

		bot.forEach((value, key) => {
			botArray.push({ key, value });
		});

		const array = this.fb.array(
			botArray.map((x) =>
				this.fb.group({
					teamId: this.fb.control(x.key),
					value: this.fb.control(x.value)
				})
			) || []
		);

		return array;
	}

	private createPickGroup(picks: Map<string, number>): FormArray {
		const pickArray: any[] = [];

		picks?.forEach((value, key) => {
			pickArray.push({ key, value });
		});

		const array = this.fb.array(
			pickArray.map((x) =>
				this.fb.group({
					teamId: this.fb.control(x.key),
					value: this.fb.control(x.value)
				})
			) || []
		);

		return array;
	}
}
