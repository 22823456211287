import { createSelector, createFeatureSelector } from "@ngrx/store";
import { GroupState, selectAllGroups, selectGroupIds } from "./group.reducers";
import { IGroup } from "src/app/models/group";

export const groupStateSelector = createFeatureSelector<GroupState>("group");

export const groups = createSelector(groupStateSelector, selectAllGroups);

export const groupIds = createSelector(groupStateSelector, selectGroupIds);

export const selectedGroupId = createSelector(groupStateSelector, (state) => state.selectedGroupId);

export const liveData = createSelector(groupStateSelector, (state) => state.liveData);

export const joiningGroup = createSelector(groupStateSelector, (state) => state.joiningGroup);

export const leavingGroup = createSelector(groupStateSelector, (state) => state.leavingGroup);

export const currentGroup = createSelector(groups, selectedGroupId, (groups, selectedGroupId) =>
	!!selectedGroupId ? groups?.find((x) => x.id === selectedGroupId) : undefined
);

export const scores = createSelector(currentGroup, (currentGroup) => currentGroup?.scores);

export const currentGroupManagers = createSelector(currentGroup, (currentGroup) => currentGroup?.managerIds);

export const currentGroupRosters = createSelector(currentGroup, (currentGroup) => currentGroup?.rosters);

export const managerColors = createSelector(currentGroup, (currentGroup) => currentGroup?.managerColors);

export const publicRoomsWithCapacity = createSelector(groupStateSelector, (state) => state.publicRoomsWithCapacity);

export const currentGroupStandings = createSelector(currentGroup, (currentGroup) => getStandings(currentGroup));

export const currentManagerScores = createSelector(currentGroup, (currentGroup) => getManagerScores(currentGroup));

export const currentGroupStatus = createSelector(currentGroup, (currentGroup) => currentGroup?.status);

export const getStandingByGroupId = (groupId: string | undefined) =>
	createSelector(groups, (groups: IGroup[]) => {
		const group = groups.find((x) => x.id === groupId);
		return getStandings(group);
	});

function getManagerScores(group: IGroup | undefined): Map<string, number> {
	if (group == null) {
		return new Map<string, number>();
	}

	const mangerIds = group.managerIds;
	const scoreMap = new Map<string, number>();

	mangerIds.forEach((uid) => {
		const roster = group.rosters.get(uid);
		let score = 0;

		roster?.forEach((team) => {
			score += group.scores.has(team) ? group.scores.get(team)!! : 0;
		});

		scoreMap.set(uid, score);
	});

	return scoreMap;
}

function getStandings(group: IGroup | undefined): Map<string, number> {
	if (group == null) {
		return new Map<string, number>();
	}

	const mangerIds = group.managerIds;
	const scoreMap = new Map<string, number>();
	let managerScores: { uid: string; score: number }[] = [];

	mangerIds.forEach((uid) => {
		const roster = group.rosters.get(uid);
		let score = 0;

		roster?.forEach((team) => {
			score += group.scores.has(team) ? group.scores.get(team)!! : 0;
		});

		managerScores.push({ uid, score });
	});

	managerScores = managerScores.sort((n1, n2) => n2.score - n1.score);

	managerScores.forEach((score, i) => {
		scoreMap.set(score.uid, i + 1);
	});

	return scoreMap;
}
