import { Action, createReducer, on } from "@ngrx/store";
import { IAuction } from "src/app/models/auction";
import * as auctionActions from "./auction.actions";
import { logoutSuccess } from "../app/app.actions";

export interface AuctionState {
	auctionData: IAuction | undefined;
	submittingBid: boolean;
	startingAuction: boolean;
	timeOffset: number;
}

export const InitialState: AuctionState = {
	auctionData: undefined,
	submittingBid: false,
	startingAuction: false,
	timeOffset: 0
};

const reducer = createReducer(
	InitialState,
	on(auctionActions.storeAuctionData, (state, { auction }) => ({ ...state, auctionData: auction })),
	on(auctionActions.submitBid, (state) => ({ ...state, submittingBid: true })),
	on(auctionActions.submitBidSuccess, (state) => ({ ...state, submittingBid: false })),
	on(auctionActions.submitBidError, (state) => ({ ...state, submittingBid: false })),
	on(auctionActions.startAuction, (state) => ({ ...state, startingAuction: true })),
	on(auctionActions.startAuctionSuccess, (state) => ({ ...state, startingAuction: false })),
	on(auctionActions.startAuctionError, (state) => ({ ...state, startingAuction: false })),
	on(auctionActions.updateTimeOffset, (state, { offset }) => ({ ...state, timeOffset: offset })),
	on(logoutSuccess, (state) => ({ auctionData: undefined, submittingBid: false, startingAuction: false, timeOffset: 0 }))
);

export function auctionReducers(state: AuctionState | undefined, action: Action) {
	return reducer(state, action);
}
