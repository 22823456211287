import { createAction, props } from "@ngrx/store";
import { IAuction } from "src/app/models/auction";

export const setCurrentAuction = createAction("[Auction] Start watching auction events", props<{ auctionId: string }>());
export const storeAuctionData = createAction("[Auction] Store auction updates in state", props<{ auction: IAuction | undefined }>());
export const storeAuctionDataError = createAction("[Auction] Auction Event Error", props<{ error: string }>());

export const getTimeOffset = createAction("[Auction] Get Time Offset");
export const updateTimeOffset = createAction("[Auction] Set Time Offset", props<{ offset: number }>());

export const startAuction = createAction("[Auction] Start auction", props<{ auctionId: string }>());
export const startAuctionSuccess = createAction("[Auction] Start auction Success");
export const startAuctionError = createAction("[Auction] Start auction Error", props<{ error: string }>());

export const submitBid = createAction("[Auction] Submit Bid", props<{ bid: number }>());
export const submitBidSuccess = createAction("[Auction] Submit Bid Success");
export const submitBidError = createAction("[Auction] Submit Bid Error", props<{ error: string }>());

export const notInterested = createAction("[Auction] Not Interested");
export const notInterestedSuccess = createAction("[Auction] Not Interested Success");
export const notInterestedError = createAction("[Auction] Not Interested Error", props<{ error: string }>());
