<div cdkOverlayOrigin #tooltip="cdkOverlayOrigin" (mouseenter)="isOpen = true" (mouseleave)="isOpen = false">
	<ng-content select="[tooltip-content]"></ng-content>
</div>

<ng-template
	cdkConnectedOverlay
	[cdkConnectedOverlayOrigin]="tooltip"
	[cdkConnectedOverlayOpen]="isOpen"
	[cdkConnectedOverlayOffsetX]="0"
	[cdkConnectedOverlayOffsetY]="5"
	(backdropClick)="isOpen = !isOpen"
>
	<div class="tooltip mat-elevation-z8">
		<ng-content select="[tooltip]"></ng-content>
	</div>
</ng-template>
