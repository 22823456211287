import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable } from "rxjs";
import { IEvent } from "src/app/models/event";
import { BaseComponent } from "src/app/shared/components/base.component";
import * as eventActions from "src/app/state/event/event.actions";
import { currentEvent } from "src/app/state/event/event.selectors";
import * as groupActions from "src/app/state/group/group.actions";
import { CommonModule } from "@angular/common";
import { EventNavigationComponent } from "src/app/shared/components/event-navigation/event-navigation.component";
import { isMobile } from "src/app/state/app/app.selectors";

@Component({
	selector: "am-event-profile",
	templateUrl: "./event-panel.component.html",
	styleUrls: ["./event-panel.component.scss"],
	standalone: true,
	imports: [CommonModule, RouterOutlet, EventNavigationComponent]
})
export class EventPanelComponent extends BaseComponent implements OnInit {
	isMobile$ = this.store.select(isMobile);
	event$: Observable<IEvent | undefined>;

	showGroupSideNav$ = new BehaviorSubject<boolean>(true);
	selectedTierId$ = new BehaviorSubject<string | undefined>(undefined);

	selectedPage: string;

	constructor(
		private store: Store,
		private route: ActivatedRoute,
		private router: Router
	) {
		super();
	}

	ngOnInit(): void {
		const eventId = this.route.snapshot.params["eventId"];
		const groupId = this.route.snapshot.params["groupId"];
		this.selectedPage = this.router.url.split("/")[4];

		this.store.dispatch(
			eventActions.setSelectedEvent({
				eventId: eventId
			})
		);

		if (groupId) {
			this.store.dispatch(groupActions.setSelectedGroup({ eventId: eventId, groupId: groupId }));
		}

		this.event$ = this.store.select(currentEvent);
	}
}
