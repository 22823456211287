export const environment = {
	firebase: {
		projectId: "auction-madness",
		appId: "1:172578018739:web:f85d5bcca1faa4a3cfe7c6",
		storageBucket: "auction-madness.appspot.com",
		apiKey: "AIzaSyCpLlBNDv90qubqk1qYYHI499RTLG1_360",
		authDomain: "auction-madness.firebaseapp.com",
		messagingSenderId: "172578018739",
		measurementId: "G-07R9M02LC9",
		vapidKey: "BAteplR3acnEVSk2-KrlaOTyAGLYZi-2cHsff-KhMUzEk0st6E6nToTxHUYI7yVUNcEe3CZ-EnWXDXVt9Gor48g"
	},
	useEmulators: false,
	functionBaseUrl: "https://us-central1-auction-madness.cloudfunctions.net/",
	production: true
};
