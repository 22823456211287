<ng-container *ngIf="isMobile$ | async; else desktopFooter">
	<div id="mobile-footer">
		<div class="content">
			<a mat-stroked-button color="default" routerLink="/cookies">Cookies</a>
			<a mat-stroked-button color="default" routerLink="/terms-of-service">Terms of Service</a>
			<a mat-stroked-button color="default" routerLink="/privacy-policy">Privacy Policy</a>
			<a mat-stroked-button color="default" href="mailto:contactus@auctiongap.com">Contact Us</a>
		</div>
		<div class="copyright">
			<div>Copyright &copy; {{ copyrightYear }} auctiongap.com</div>
		</div>
	</div>
</ng-container>
<ng-template #desktopFooter>
	<div id="footer">
		<div class="content">
			<div class="links">
				<a mat-stroked-button color="default" routerLink="/cookies">Cookies</a>&#x2022;
				<a mat-stroked-button color="default" routerLink="/terms-of-service">Terms of Service</a>&#x2022;
				<a mat-stroked-button color="default" routerLink="/privacy-policy">Privacy Policy</a>&#x2022;
				<a mat-stroked-button color="default" href="mailto:contactus@auctiongap.com">Contact Us</a>
			</div>
		</div>

		<div></div>

		<div class="copyright">Copyright &copy; {{ copyrightYear }} auctiongap.com</div>
	</div>
</ng-template>
