import { createAction, props } from "@ngrx/store";
import { IPrivate, IUser } from "src/app/models/user";

export const getPrivateUserData = createAction("[Profile] Load Private User Data", props<{ uid: string }>());
export const getPrivateUserDataSuccess = createAction("[Profile] Load Private User Data Success", props<{ privateInfo: IPrivate }>());
export const getPrivateUserDataFailure = createAction("[Profile] Load Private User Data Failure", props<{ errorMessage: string }>());

export const updateEmailPreference = createAction("[Profile] Update Email Notifications", props<{ newValue: boolean }>());
export const updateEmailPreferenceSuccess = createAction("[Profile] Update Email Notifications Success", props<{ newValue: boolean }>());
export const updateEmailPreferenceError = createAction("[Profile] Update Email Notifications Error", props<{ error: string }>());

export const registerPushNotifications = createAction("[Profile] Register Push Notifications", props<{ identifier: string }>());
export const registerPushNotificationsSuccess = createAction(
	"[Profile] Register Push Notifications Success",
	props<{ identifier: string; token: string }>()
);
export const registerPushNotificationsError = createAction("[Profile] Register Push Notifications Error", props<{ error: string }>());

export const removePushNotificationRegistrations = createAction(
	"[Profile] Remove Push Notifications Registration",
	props<{ tokens: string[] }>()
);
export const removePushNotificationRegistrationsSuccess = createAction(
	"[Profile] Remove Push Notifications Registration Success",
	props<{ tokens: string[] }>()
);
export const removePushNotificationRegistrationsError = createAction(
	"[Profile] Remove Push Notifications Registration Error",
	props<{ error: string }>()
);

export const watchForPushNotifications = createAction("[Profile] Watch For Push Notifications");
export const receivePushNotification = createAction("[Profile] Receive Push Notification");
