import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { environment } from "src/environments/environment";
import { FirebaseMessaging, GetTokenOptions } from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";

@Injectable({
	providedIn: "root"
})
export class CloudMessagingService {
	constructor() {}

	async request() {
		const options: GetTokenOptions = {
			vapidKey: environment.firebase.vapidKey
		};
		if (Capacitor.getPlatform() === "web") {
			options.serviceWorkerRegistration = await navigator.serviceWorker.register("firebase-messaging-sw.js");
		} else {
			let permCheck = await FirebaseMessaging.checkPermissions();
			if (permCheck.receive === "prompt") {
				await FirebaseMessaging.requestPermissions();
			} else if (permCheck.receive !== "granted") {
				throw new Error("User denied permissions!");
			}
		}
		const { token } = await FirebaseMessaging.getToken(options);
		return token;
	}

	// async deleteToken() {
	// 	await FirebaseMessaging.deleteToken();
	// }
}
