import { createAction, props } from "@ngrx/store";

export const checkLogin = createAction("[App] Check For Logged In User");
export const updateCurrentUser = createAction("[App] Set Logged In UserID", props<{ uid: string; claims?: { roles: string[] } }>());
export const logout = createAction("[App] Logout Current User");
export const logoutSuccess = createAction("[App] Logout Success");

export const updateCurrentBreakPoint = createAction("[App] Update Current Break Point", props<{ currentBreakPoint: string }>());

export const updateShowEliminated = createAction("[App] Update Show Eliminated", props<{ showEliminated: boolean }>());

export const getConfigs = createAction("[App] Get Configs");
export const setConfigs = createAction(
	"[App] Set Configs",
	props<{
		configs: {
			[key: string]: string | number | boolean;
		};
	}>()
);
