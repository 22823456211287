import { Injectable } from "@angular/core";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";

@Injectable({
	providedIn: "root"
})
export class AnalyticsService {
	constructor() {}

	async setUserID(userID: string) {
		await FirebaseAnalytics.setUserId({ userId: userID });
	}

	async logEvent(
		eventName: string,
		eventParams?: {
			[key: string]: any;
		}
	) {
		await FirebaseAnalytics.logEvent({ name: eventName, params: eventParams });
	}

	async setPageName(pageName: string) {
		await FirebaseAnalytics.setCurrentScreen({ screenName: pageName });
	}
}
