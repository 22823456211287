<mat-dialog-content>
	<h2>Terms and Conditions / Privacy Policy</h2>
	By clicking "Agree" here, I state that I have read and understand the
	<a routerLink="/terms-of-service" target="_blank">Terms of Service</a> and
	<a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>
	<mat-dialog-actions>
		<button mat-stroked-button color="warn" (click)="cancel()">Cancel</button>
		<button mat-stroked-button class="left" (click)="agree()">Agree</button>
	</mat-dialog-actions>
</mat-dialog-content>
