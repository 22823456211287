import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, switchMap, of, map, withLatestFrom, filter } from "rxjs";
import { AuctionService } from "src/app/services/auction.service";
import * as auctionActions from "./auction.actions";
import { auction } from "./auction.selectors";
import { SnackbarService } from "src/app/services/snackbar.service";

@Injectable()
export class AuctionEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private auctionService: AuctionService,
		private snackbar: SnackbarService
	) {}

	getServerTimeOffset$ = createEffect(() =>
		this.actions$.pipe(
			ofType(auctionActions.getTimeOffset),
			switchMap(() =>
				this.auctionService
					.getServerTime()
					.pipe(map((time) => auctionActions.updateTimeOffset({ offset: new Date().getTime() - new Date(time).getTime() })))
			)
		)
	);

	watchCurrentAuction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(auctionActions.setCurrentAuction),
			switchMap((action) => {
				if (!action.auctionId) {
					return of(undefined);
				} else {
					return this.auctionService.getbyId(action.auctionId);
				}
			}),
			map((auction) => auctionActions.storeAuctionData({ auction: auction })),
			catchError((error) => of(auctionActions.storeAuctionDataError(error)))
		)
	);

	startAuction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(auctionActions.startAuction),
			switchMap((action) =>
				this.auctionService.startAuction(action.auctionId).pipe(
					map((auction) => auctionActions.startAuctionSuccess()),
					catchError((error) => {
						this.snackbar.error(`Failed to start Auction: ${error.message}`);
						return of(auctionActions.startAuctionError(error));
					})
				)
			)
		)
	);

	submitBid$ = createEffect(() =>
		this.actions$.pipe(
			ofType(auctionActions.submitBid),
			withLatestFrom(this.store.select(auction)),
			switchMap(([action, auction]) =>
				this.auctionService.submitBid(auction?.id || "", auction?.currentTeam || "", action.bid).pipe(
					map((auction) => auctionActions.submitBidSuccess()),
					catchError((error) => of(auctionActions.submitBidError(error)))
				)
			)
		)
	);

	notInterested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(auctionActions.notInterested),
			withLatestFrom(this.store.select(auction)),
			switchMap(([_, auction]) =>
				this.auctionService.pass(auction?.id).pipe(
					map(() => auctionActions.notInterestedSuccess()),
					catchError((error) => of(auctionActions.notInterestedError(error)))
				)
			)
		)
	);
}
