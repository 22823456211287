<div class="invite-page">
	<ng-container *ngIf="loaded$ | async; else loading">
		<ng-container *ngIf="invite$ | async as invite; else invalidInvite">
			<ng-container *ngIf="currentEvent$ | async as inviteEvent">
				<span>
					<img
						width="300"
						[src]="'../assets/icons/logos/' + (inviteEvent?.type ? inviteEvent.type.toLowerCase() : 'generic') + '.svg'"
					/>
				</span>
				<h2>You have been invited to <br />{{ inviteEvent.name }}</h2>

				<button mat-stroked-button *ngIf="!(isUserLoggedIn$ | async)" (click)="login()">
					You must login/signup to join this group
				</button>

				<ng-container *ngIf="isUserLoggedIn$ | async">
					<ng-container *ngIf="currentEvent$ | async as currentEvent">
						<h3>Would you like to join: {{ invite.groupName }}</h3>
						<am-loading-button color="primary" [loading]="joining" title="Join!" (click)="joinGroup(invite, currentEvent.type)">
						</am-loading-button>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
		<ng-template #invalidInvite>
			<h3>Uh oh, it looks like you stumbled across an invalid invite. Please reach out to the group owner to resend.</h3>
		</ng-template>
	</ng-container>
	<ng-template #loading>
		<mat-progress-spinner></mat-progress-spinner>
	</ng-template>
</div>
