<div class="scoring-overview">
	<h2>Scoring Overview</h2>

	<div class="scoring-info">
		<b>Total Prize: {{ totalPot | currency : "USD" : "symbol" : "1.2-2" }}</b>
	</div>
	<mat-divider></mat-divider>
	<div *ngFor="let phase of phases" class="scoring-info">
		<div *ngIf="phase.amountToMakePhase !== 0">
			Each team that reaches <b>{{ phase.name }}</b> earns {{ phase.amountToMakePhase | currency : "USD" : "symbol" : "1.2-2" }}
		</div>
		<ng-container *ngIf="phase.amountForAllTiers === 0">
			<div *ngFor="let tier of phase.tiers">
				<b>{{ tier.name }}</b> game winners earn {{ tier.amountForTier | currency : "USD" : "symbol" : "1.2-2" }}
				<mat-icon *ngIf="" style="height: 20px; font-size: 20px; margin: auto"> dynamic_form </mat-icon>
			</div>
		</ng-container>
		<div *ngIf="phase.amountForAllTiers !== 0">
			Each <b>{{ phase.name }}</b> game winners earn {{ phase.amountForAllTiers | currency : "USD" : "symbol" : "1.2-2" }}
		</div>
	</div>
</div>
