import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { withLatestFrom, switchMap, map, catchError, filter } from "rxjs/operators";
import * as userActions from "./user.actions";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { UserState, selectUserIds } from "./user.reducers";

@Injectable()
export class UserEffects {
	constructor(private actions$: Actions, private userService: UserService, private store: Store<UserState>) {}

	getUserData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.getUserData),
			filter((action) => !!action.userId),
			switchMap((action) => {
				return this.userService.getFullUserInfoByID(action.userId).pipe(
					map((data) => {
						if (!data) {
							return userActions.getUserDataError({ errorMessage: "Unable to find user" });
						}

						return userActions.getUserDataSuccess({ user: data });
					}),
					catchError((error) => {
						return of(userActions.getUserDataError({ errorMessage: error }));
					})
				);
			})
		)
	);

	getUsersData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userActions.getUsersData),
			filter((action) => action.userIds?.length > 0),
			withLatestFrom(this.store.select(selectUserIds)),
			switchMap(([action, userIds]) => {
				let cachedIDs = userIds ? (userIds as string[]) : [];
				let filteredUserIDs = action.userIds.filter((id) => !cachedIDs.includes(id));
				if (filteredUserIDs.length > 0) {
					return this.userService.getBasicUserInfoByIDs(filteredUserIDs).pipe(
						map((data) => userActions.getUsersDataSuccess({ usersData: data })),
						catchError((error) => of({ type: "Error", error }))
					);
				} else {
					return [userActions.getUsersDataCached()];
				}
			})
		)
	);
}
