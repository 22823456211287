import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, switchMap } from "rxjs";
import { AuthenticationService } from "../authentication.service";
import { environment } from "src/environments/environment";

export interface IRequestOptions {
	headers?: HttpHeaders;
	observe?: "body";
	params?: HttpParams;
	reportProgress?: boolean;
	responseType?: undefined;
	withCredentials?: boolean;
	body?: any;
}

@Injectable()
export class FunctionClient {
	private api;

	public constructor(private http: HttpClient, private authService: AuthenticationService) {
		this.api = environment.functionBaseUrl;
	}

	/**
	 * GET request
	 * @param {string} endPoint it doesn't need / in front of the end point
	 * @param {IRequestOptions} options options of the request like headers, body, etc.
	 * @returns {Observable<T>}
	 */
	public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
		return from(this.AddAuthHeader(options)).pipe(
			switchMap((newOptions) => {
				return this.http.get<T>(this.api + endPoint, newOptions);
			})
		);
	}

	/**
	 * POST request
	 * @param {string} endPoint end point of the api
	 * @param {Object} body body of the request.
	 * @param {IRequestOptions} options options of the request like headers, body, etc.
	 * @returns {Observable<T>}
	 */
	public Post<T>(endPoint: string, body?: Object, options?: IRequestOptions): Observable<T> {
		return from(this.AddAuthHeader(options)).pipe(
			switchMap((newOptions) => {
				return this.http.post<T>(this.api + endPoint, body, newOptions);
			})
		);
	}

	/**
	 * PUT request
	 * @param {string} endPoint end point of the api
	 * @param {Object} body body of the request.
	 * @param {IRequestOptions} options options of the request like headers, body, etc.
	 * @returns {Observable<T>}
	 */
	public Put<T>(endPoint: string, body?: Object, options?: IRequestOptions): Observable<T> {
		return from(this.AddAuthHeader(options)).pipe(
			switchMap((newOptions) => {
				return this.http.put<T>(this.api + endPoint, body, newOptions);
			})
		);
	}

	/**
	 * DELETE request
	 * @param {string} endPoint end point of the api
	 * @param {IRequestOptions} options options of the request like headers, body, etc.
	 * @returns {Observable<T>}
	 */
	public Delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
		return from(this.AddAuthHeader(options)).pipe(
			switchMap((newOptions) => {
				return this.http.delete<T>(this.api + endPoint, newOptions);
			})
		);
	}

	private async AddAuthHeader(options?: IRequestOptions) {
		const user = await this.authService.getCurrentUser();
		const token = await user?.getIdToken();

		if (!options) {
			options = {} as IRequestOptions;
		}
		if (!options.headers) {
			options.headers = new HttpHeaders({
				Authorization: "Bearer " + token
			});
		} else {
			options.headers?.set("Authorization", "Bearer " + token);
		}

		return options;
	}
}
