import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from "@angular/material/snack-bar";
import { ISnackbarMessage, SnackbarComponent } from "../shared/components/snackbar/snackbar.component";

@Injectable({
	providedIn: "root"
})
export class SnackbarService {
	defaultConfig: MatSnackBarConfig<ISnackbarMessage> = { duration: 3500, verticalPosition: "top", horizontalPosition: "end" };

	constructor(private snackBar: MatSnackBar) {}

	success(
		message: string,
		action: string = "Dismiss",
		config: MatSnackBarConfig<ISnackbarMessage> = this.defaultConfig
	): MatSnackBarRef<SnackbarComponent> {
		config.panelClass = "success";
		return this.open(message, action, config);
	}

	warn(
		message: string,
		action: string = "Dismiss",
		config: MatSnackBarConfig<ISnackbarMessage> = this.defaultConfig
	): MatSnackBarRef<SnackbarComponent> {
		config.panelClass = "warning";
		return this.open(message, action, config);
	}

	error(
		message: string,
		action: string = "Dismiss",
		config: MatSnackBarConfig<ISnackbarMessage> = this.defaultConfig
	): MatSnackBarRef<SnackbarComponent> {
		config.panelClass = "error";
		return this.open(message, action, config);
	}

	open(message: string, action: string = "Dismiss", config?: MatSnackBarConfig<ISnackbarMessage>): MatSnackBarRef<SnackbarComponent> {
		config = { ...config, data: { message: message, action: action } };
		return this.snackBar.openFromComponent(SnackbarComponent, config);
	}
}
