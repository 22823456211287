import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { IPrivate, IUser } from "src/app/models/user";
import * as UserActions from "./user.actions";
import { logoutSuccess } from "../app/app.actions";

export interface UserState extends EntityState<IUser> {}

export function selectUserId(a: IUser): string {
	return a.uid;
}

const adapter: EntityAdapter<IUser> = createEntityAdapter<IUser>({
	selectId: selectUserId
});

export const InitialState: UserState = adapter.getInitialState({});

const reducer = createReducer(
	InitialState,
	on(UserActions.getUserDataSuccess, (state, { user: userData }) => adapter.upsertOne(userData, state)),
	on(UserActions.getUsersDataSuccess, (state, { usersData }) => adapter.upsertMany(usersData, state)),
	on(logoutSuccess, () => InitialState)
);

export function userReducer(state: UserState | undefined, action: Action) {
	return reducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectUserIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllUsers = selectAll;

// select the total user count
export const selectUserTotal = selectTotal;
