import { Component, computed, inject, input, Input, OnInit, signal, Signal } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { isFuture } from "date-fns";
import { Observable } from "rxjs";
import { IGroup } from "src/app/models/group";
import { ITeam } from "src/app/models/team";
import { SnackbarService } from "src/app/services/snackbar.service";
import { BaseComponent } from "src/app/shared/components/base.component";
import { getStandingByGroupId } from "src/app/state/group/group.selectors";
import { ScoreboardPlacePipe } from "../../pipes/scoreboard-place.pipe";
import { CountdownPipe } from "../../pipes/countdown.pipe";
import { MatTooltip } from "@angular/material/tooltip";
import { MatButton } from "@angular/material/button";
import { MatDivider } from "@angular/material/divider";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { LeagueStore } from "src/app/state/league.store";
import { toSignal } from "@angular/core/rxjs-interop";
import { events } from "src/app/state/event/event.selectors";
import { ILeague } from "src/app/models/league";

@Component({
	selector: "am-group-card",
	templateUrl: "./group-card.component.html",
	styleUrls: ["./group-card.component.scss"],
	standalone: true,
	imports: [CommonModule, MatCardModule, MatDivider, MatButton, MatTooltip, CountdownPipe, ScoreboardPlacePipe]
})
export class GroupCardComponent extends BaseComponent implements OnInit {
	@Input() group: IGroup;
	eventId = input.required<string>();
	@Input() uid: string;
	@Input() isMobile: boolean | null = false;

	readonly leagueStore = inject(LeagueStore);
	teams: Signal<ITeam[] | undefined> = computed(() => this.leagueStore.activeLeagues().find((x) => x.id === this.league()?.id)?.teams);
	standing$: Observable<Map<string, number>>;
	events = toSignal(this.store.select(events));

	league = computed<ILeague | undefined>(() => {
		const event = this.events()?.find((x) => x.id === this.eventId());
		return this.leagueStore.activeLeagues().find((x) => x.id === event?.leagueId);
	});

	type = computed<string | undefined>(() => {
		return this.league()?.type.toLowerCase();
	});

	constructor(
		private router: Router,
		private store: Store,
		private snackbar: SnackbarService
	) {
		super();
	}

	ngOnInit(): void {
		this.standing$ = this.store.select(getStandingByGroupId(this.group.id));
	}

	navigateToGroup(group: IGroup): void {
		if (group.status === "Ready" || group.status === "In Auction") {
			this.router.navigate(["auction", group.auctionId]);
		} else {
			this.router.navigate(["event", this.eventId(), group.id]);
		}
	}

	determineGroupState(): string {
		// Missing Managers
		if (this.group.managerIds.length !== this.group.numOfManagers) {
			return "WaitingOnManagers";
		}

		// Auction hasn't started
		if (this.group.auctionDate && this.isAuctionInFuture(this.group)) {
			return "WaitingOnAuction";
		}

		// Auction is Currently happening
		if (
			this.group.status === "In Auction" ||
			(this.group.auctionDate && !this.isAuctionInFuture(this.group) && this.group.status === "Ready")
		) {
			return "AuctionHappening";
		}

		if (this.group.status === "In Progress") {
			return "InProgress";
		}

		return "Error";
	}

	getCurrentUserScore(): number {
		if (this.uid && this.group && this.group.rosters.has(this.uid)) {
			const currentUserRoster = this.group.rosters.get(this.uid)!!;
			let score = 0;

			currentUserRoster.forEach((team) => {
				score += this.group.scores.has(team) ? this.group.scores.get(team)!! : 0;
			});

			return score;
		}

		return -1;
	}

	getCurrentUsersTeams(teams: ITeam[]): ITeam[] {
		if (this.uid && this.group && this.group.rosters.has(this.uid)) {
			const currentUserRoster = this.group.rosters.get(this.uid)!!;

			return teams.filter((x) => currentUserRoster.includes(x.id)).sort((a, b) => Number(a.isEliminated) - Number(b.isEliminated));
		}

		return [];
	}

	isAuctionInFuture = (group: IGroup) => isFuture(group.auctionDate!!);

	copyInviteLink(event: any, group: IGroup): void {
		event.stopPropagation();
		navigator.clipboard.writeText(`https://auctiongap.com/invite?inviteId=${group?.sharedInviteId}`);
		this.snackbar.success("Copied the invite link to the clipboard.");
	}
}
