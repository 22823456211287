<button mat-button (click)="goToLogin()"><mat-icon>chevron_left</mat-icon>Return to Login</button>
<form [formGroup]="resetPassword" (submit)="submitResetPassword()" *ngIf="!success; else successTemplate" class="reset-form">
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" autocomplete="email username">
        <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="email.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <mat-error *ngIf="errorMessage">
      {{errorMessage}}
    </mat-error>
    <button mat-raised-button color="accent" [disabled]="!resetPassword.valid || (resetPassword.pristine) || updating">Send Password Reset Email</button>
</form>

<ng-template #successTemplate>
    <h2>Password reset request sent. Please check your email to complete password reset!</h2>
<ng-template>