<h2 mat-dialog-title>{{ mainMatchup.team1?.displayName }} vs {{ mainMatchup.team2?.displayName }}</h2>
<mat-dialog-content>
	<mat-divider></mat-divider>
	<div class="header">
		<div *ngIf="mainMatchup.team1" class="container left">
			<div class="image left row-reverse">
				<h1>{{ mainMatchup.team1.score ?? 0 }}</h1>
				<img width="75" height="75" [src]="mainMatchup.team1.logo" />
			</div>
			<h5>{{ mainMatchup.team1.seed ? "#" + mainMatchup.team1.seed : "" }} {{ mainMatchup.team1.displayName }}</h5>
			<span class="record">{{ mainMatchup.team1.record ? "(" + mainMatchup.team1.record + ")" : "" }}</span>
			<ng-container *ngIf="mainMatchup.team1Owner">
				<span class="spacer"></span>
				<div class="footer">{{ mainMatchup.team1Owner.owner }}</div>
				<div class="color-block color-right" [style.background-color]="mainMatchup.team1Owner.color"></div>
			</ng-container>
		</div>
		<div class="divider-container">
			<mat-divider class="divider" [vertical]="true"></mat-divider>
			<span>vs</span>
			<span matTooltip="The game's value" class="value">{{ mainMatchup.value | currency : "USD" : "symbol" : "1.2-2" }}</span>

			<mat-divider class="divider" [vertical]="true"></mat-divider>
		</div>
		<div *ngIf="mainMatchup.team2" class="container right">
			<div class="image left">
				<h1>{{ mainMatchup.team2.score ?? 0 }}</h1>
				<img width="75" height="75" [src]="mainMatchup.team2.logo" />
			</div>
			<h5>{{ mainMatchup.team2.seed ? "#" + mainMatchup.team2.seed : "" }} {{ mainMatchup.team2.displayName }}</h5>
			<span class="record">{{ mainMatchup.team2.record ? "(" + mainMatchup.team2.record + ")" : "" }}</span>
			<ng-container *ngIf="mainMatchup.team2Owner">
				<span class="spacer"></span>
				<div class="footer">{{ mainMatchup.team2Owner.owner }}</div>
				<div class="color-block color-right" [style.background-color]="mainMatchup.team2Owner.color"></div>
			</ng-container>
		</div>
	</div>
	<mat-accordion>
		<mat-expansion-panel *ngFor="let matchup of orderSubMatchups; let index = index">
			<mat-expansion-panel-header>
				<mat-panel-title>Game {{ index + 1 }}</mat-panel-title>
				<mat-panel-description>
					{{ matchup.team1?.abbreviation }} {{ matchup.gameStatus !== "Upcoming" ? matchup.team1?.score ?? 0 : "" }} vs
					{{ matchup.team2?.abbreviation }}
					{{ matchup.gameStatus !== "Upcoming" ? matchup.team2?.score ?? 0 : "" }}
				</mat-panel-description>
			</mat-expansion-panel-header>
			<mat-divider></mat-divider>
			<div class="panel">
				<div class="flex center">
					<div class="detail-header" style="text-align: right">
						{{ matchup.startTime != null ? (matchup.startTime | date : "M/dd h:mm a") : "" }}
						{{ matchup.tvProvider != null ? "on " + matchup.tvProvider : "" }}
					</div>
					<mat-divider [vertical]="true"></mat-divider>
					<div matTooltip="The game's value" class="detail-header" style="text-align: left">
						{{ matchup.value | currency : "USD" : "symbol" : "1.2-2" }}
					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="details">
					<div *ngIf="matchup.team1">
						<div class="flex">
							<img width="50" height="50" [src]="matchup.team1.logo" />
							<h1>{{ matchup.team1.score ?? 0 }}</h1>
						</div>
						<h5>{{ matchup.team1.seed ? "#" + matchup.team1.seed : "" }} {{ matchup.team1.displayName }}</h5>
					</div>
					<mat-divider vertical="true"></mat-divider>
					<div *ngIf="matchup.team2">
						<div class="flex row-reverse">
							<img width="50" height="50" [src]="matchup.team2.logo" />
							<h1>{{ matchup.team2.score ?? 0 }}</h1>
						</div>
						<h5 class="align-right">
							{{ matchup.team2.seed ? "#" + matchup.team2.seed : "" }} {{ matchup.team2.displayName }}
						</h5>
					</div>
				</div>

				<iframe
					*ngIf="matchup.highlightLink"
					[src]="matchup.highlightLink"
					[ngClass]="{ 'small-iframe': isMobile }"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
