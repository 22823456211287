<button mat-button (click)="goToLogin()"><mat-icon>chevron_left</mat-icon>Return to Login</button>
<form [formGroup]="resetPassword" class="signup-form" (submit)="updatePassword()" *ngIf="!success; else successTemplate">
    <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" autocomplete="new-password">
        <mat-error *ngIf="password.hasError('required')">
          Passsword is <strong>required</strong>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Verify Password</mat-label>
        <input matInput type="password" formControlName="password2" autocomplete="new-password">
    </mat-form-field>
    <mat-error *ngIf="resetPassword.hasError('match_error')">
      Passwords dont match
    </mat-error>
    <mat-error *ngIf="errorMessage">
      {{errorMessage}}
    </mat-error>
    <button mat-raised-button color="accent" [disabled]="!resetPassword.valid || (resetPassword.pristine) || updating">Reset Password</button>
</form>

<ng-template #successTemplate>
    <h2>Password updated!</h2>
    <button mat-button (click)="goToLogin()">Go to Login</button>
<ng-template>
