import { Action, createReducer, on } from "@ngrx/store";
import { IPrivate } from "src/app/models/user";
import * as ProfileActions from "./profile.actions";
import { logoutSuccess } from "../app/app.actions";

export interface ProfileState {
	updating: boolean;
	privateUserInfo?: IPrivate;
	privateInfoLoaded: boolean;
}

export const InitialState: ProfileState = {
	updating: false,
	privateUserInfo: undefined,
	privateInfoLoaded: false
};

const reducer = createReducer(
	InitialState,
	on(ProfileActions.getPrivateUserDataSuccess, (state, { privateInfo }) => ({
		...state,
		privateUserInfo: privateInfo,
		privateInfoLoaded: true
	})),
	on(ProfileActions.getPrivateUserDataFailure, (state, { errorMessage }) => ({ ...state, privateInfoLoaded: true })),
	on(ProfileActions.updateEmailPreference, (state, {}) => ({ ...state, updating: true })),
	on(ProfileActions.updateEmailPreferenceSuccess, (state, {}) => ({ ...state, updating: false })),
	on(ProfileActions.updateEmailPreferenceError, (state, {}) => ({ ...state, updating: false })),
	on(ProfileActions.registerPushNotifications, (state, {}) => ({ ...state, updating: true })),
	on(ProfileActions.registerPushNotificationsSuccess, (state, {}) => ({ ...state, updating: false })),
	on(ProfileActions.registerPushNotificationsError, (state, {}) => ({ ...state, updating: false })),
	on(ProfileActions.removePushNotificationRegistrations, (state, {}) => ({ ...state, updating: true })),
	on(ProfileActions.removePushNotificationRegistrationsSuccess, (state, {}) => ({ ...state, updating: false })),
	on(ProfileActions.removePushNotificationRegistrationsError, (state, {}) => ({ ...state, updating: false })),
	on(logoutSuccess, () => InitialState)
);

export function profileReducer(state: ProfileState | undefined, action: Action) {
	return reducer(state, action);
}
