<ng-container *ngIf="isMobile$ | async; else desktopFooter">
	<div id="mobile-footer">
		<div class="content">
			<a mat-flat-button color="default" routerLink="/cookies">Cookies</a>
			<a mat-flat-button color="default" routerLink="/terms-of-service">Terms of Service</a>
			<a mat-flat-button color="default" routerLink="/privacy-policy">Privacy Policy</a>
			<a mat-flat-button color="default" href="mailto:contactus@auctiongap.com">Contact Us</a>
		</div>
		<div class="copyright">
			<div class="social-media">
				<button mat-icon-button (click)="openExternal('https://twitter.com/Auctiongap')">
					<mat-icon svgIcon="am-twitter"></mat-icon>
				</button>
				<button mat-icon-button (click)="openExternal('https://discord.com/channels/1110214606544187442/1110214885440245850')">
					<mat-icon svgIcon="am-discord"></mat-icon>
				</button>
			</div>
			<div>Copyright &copy; {{ copyrightYear }} auctiongap.com</div>
		</div>
	</div>
</ng-container>
<ng-template #desktopFooter>
	<div id="footer">
		<div class="content">
			<div class="links">
				<a mat-flat-button color="default" routerLink="/cookies">Cookies</a>&#x2022;
				<a mat-flat-button color="default" routerLink="/terms-of-service">Terms of Service</a>&#x2022;
				<a mat-flat-button color="default" routerLink="/privacy-policy">Privacy Policy</a>&#x2022;
				<a mat-flat-button color="default" href="mailto:contactus@auctiongap.com">Contact Us</a>
			</div>
			<div class="social-media">
				<button mat-icon-button (click)="openExternal('https://twitter.com/Auctiongap')">
					<mat-icon svgIcon="am-twitter"></mat-icon>
				</button>
				<button mat-icon-button (click)="openExternal('https://discord.com/channels/1110214606544187442/1110214885440245850')">
					<mat-icon svgIcon="am-discord"></mat-icon>
				</button>
			</div>
		</div>

		<div></div>

		<div class="copyright">Copyright &copy; {{ copyrightYear }} auctiongap.com</div>
	</div>
</ng-template>
