import { createSelector, createFeatureSelector } from "@ngrx/store";
import { currentUID } from "../app/app.selectors";
import * as fromUser from "./user.reducers";
import { ICurrentUser } from "src/app/models/user";
import { privateUserInfo } from "../profile/profile.selectors";

export const userState = createFeatureSelector<fromUser.UserState>("user");

export const users = createSelector(userState, fromUser.selectAllUsers);

export const usersMap = createSelector(userState, fromUser.selectUserEntities);

export const getByID = (userId: string) => createSelector(userState, usersMap, (state, users) => users[userId]);

export const getByIDs = (userIds: string[]) => createSelector(users, (users) => users.filter((u) => userIds.includes(u.uid)));

export const currentUserTutorialVersion = createSelector(currentUID, usersMap, (uid, users) => users[uid]?.tutorialVersion);

export const currentUserData = createSelector(
	usersMap,
	currentUID,
	privateUserInfo,
	(users, uid, privateUserInfo) =>
		(users && uid && ({ uid: uid, userInfo: users[uid], privateInfo: privateUserInfo } as ICurrentUser)) || undefined
);
