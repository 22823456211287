import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Injectable({
	providedIn: "any"
})
export class IconService {
	private path: string = "../assets/icons";

	constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

	createIcons(): void {
		this.matIconRegistry
			.addSvgIcon("bracket", this.setPath(`${this.path}/bracket.svg`))
			.addSvgIcon("jersey", this.setPath(`${this.path}/jersey.svg`))
			.addSvgIcon("table", this.setPath(`${this.path}/table.svg`))
			.addSvgIcon("list", this.setPath(`${this.path}/list.svg`))
			.addSvgIcon("am-twitter", this.setPath(`${this.path}/twitter.svg`))
			.addSvgIcon("am-discord", this.setPath(`${this.path}/discord.svg`))
			.addSvgIcon("am-profile", this.setPath(`${this.path}/profile-circle.svg`))
			.addSvgIcon("am-add", this.setPath(`${this.path}/plus-circle.svg`))
			.addSvgIcon("am-auction", this.setPath(`${this.path}/auction.svg`))
			.addSvgIcon("am-calendar", this.setPath(`${this.path}/calendar.svg`))
			.addSvgIcon("am-schedule", this.setPath(`${this.path}/schedule.svg`))
			.addSvgIcon("am-scoreboard", this.setPath(`${this.path}/scoreboard.svg`))
			.addSvgIcon("am-settings", this.setPath(`${this.path}/settings.svg`))
			.addSvgIcon("am-settings-white", this.setPath(`${this.path}/settings-white.svg`))
			.addSvgIcon("table_rows_narrow", this.setPath(`${this.path}/table_rows_narrow.svg`));
	}
	private setPath(url: string): SafeResourceUrl {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
