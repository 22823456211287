import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { BaseComponent } from "../base.component";
import { BehaviorSubject, combineLatest, filter, map, Observable, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { currentBreakPoint } from "src/app/state/app/app.selectors";
import { BreakPointDisplayNameMap } from "src/app/models/constants";
import { Breakpoints } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton } from "@angular/material/button";
import { IPhase, ITier } from "src/app/models/event";
import { IGame } from "src/app/models/game";
import { compare } from "../../functions/compare.function";

export interface ICalendarRound {
	name: string;
	rounds: string[];
	isDate: boolean;
	isBracket: boolean;
	isSeriesBased: boolean;
}

@Component({
	selector: "am-schedule-calendar",
	templateUrl: "./schedule-calendar.component.html",
	styleUrls: ["./schedule-calendar.component.scss"],
	standalone: true,
	imports: [MatIconButton, MatIcon, CommonModule]
})
export class ScheduleCalendarComponent extends BaseComponent implements OnInit, OnChanges {
	@Input() tiers: ITier[];
	@Input() games: IGame[];
	@Input() phases: IPhase[];
	@Input() eventStatus: "Created" | "Draft" | "Open" | "Started" | "Complete" | "Archived";
	@Input() selectedRound: ICalendarRound | null | undefined;
	@Output() selectRoundEvent = new EventEmitter<ICalendarRound>();

	selectedRoundIndex = -1;
	// I hate this...
	isLargeBreakPoint: boolean | undefined = undefined;
	isMediumBreakPoint: boolean | undefined = undefined;
	isSmallBreakpoint: boolean | undefined = undefined;
	isXSmallBreakpoint: boolean | undefined = undefined;

	tiersTaken = 0;

	roundsToDisplay$: Observable<ICalendarRound[]>;
	allRounds$ = new BehaviorSubject<ICalendarRound[]>([]);

	startIndex$ = new BehaviorSubject<number>(0);
	endIndex$ = new BehaviorSubject<number>(0);

	constructor(private store: Store) {
		super();
	}

	ngOnInit(): void {
		this.store
			.select(currentBreakPoint)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((currentBreakPoint) => {
				if (
					currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.Large) ||
					(currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.XLarge) && !this.isLargeBreakPoint)
				) {
					this.isLargeBreakPoint = true;
					this.isMediumBreakPoint = false;
					this.tiersTaken = 9;
				} else if (currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.Medium) && !this.isMediumBreakPoint) {
					this.isLargeBreakPoint = false;
					this.isSmallBreakpoint = false;
					this.isMediumBreakPoint = true;
					this.tiersTaken = 5;
				} else if (currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.Small) && !this.isSmallBreakpoint) {
					this.isMediumBreakPoint = false;
					this.isSmallBreakpoint = true;
					this.tiersTaken = 3;
				} else if (currentBreakPoint === BreakPointDisplayNameMap.get(Breakpoints.XSmall) && !this.isSmallBreakpoint) {
					this.isSmallBreakpoint = false;
					this.isXSmallBreakpoint = true;
					this.tiersTaken = 1;
				}

				this.calcWeeks();
			});

		this.roundsToDisplay$ = combineLatest([this.allRounds$, this.startIndex$, this.endIndex$]).pipe(
			takeUntil(this.destroyed$),
			filter(([allRounds, ,]) => !!allRounds && allRounds.length > 0),
			map(([allRounds, startIndex, endIndex]) => allRounds.slice(startIndex, endIndex))
		);

		const allRoundsToDisplay: ICalendarRound[] = [];

		this.phases
			.sort((a, b) => a.order - b.order)
			.forEach((phase) => {
				if (phase.viewType === "Rounds") {
					this.tiers
						.filter((x) => x.phaseId === phase.id)
						.sort((a, b) => a.order - b.order)
						.forEach((tier) => {
							const roundsToAdd = tier.rounds.map((x) => {
								return {
									name: x,
									rounds: [x],
									isDate: false,
									isBracket: false,
									isSeriesBased: phase.isSeriesBased
								} as ICalendarRound;
							});
							allRoundsToDisplay.push(...roundsToAdd);
						});
				} else if (phase.viewType === "Bracket") {
					allRoundsToDisplay.push({
						name: phase.name,
						rounds: this.tiers.filter((x) => x.phaseId === phase.id).flatMap((t) => t.rounds),
						isDate: false,
						isBracket: true,
						isSeriesBased: phase.isSeriesBased
					});
				} else {
					// Daily - Get all games in this phase and their days
					this.tiers
						.filter((x) => x.phaseId === phase.id)
						.sort((a, b) => a.order - b.order)
						.forEach((tier) => {
							const roundsToAdd = this.getDailyRounds(tier.rounds).map((x) => {
								return {
									name: x,
									rounds: [x],
									isDate: true,
									isBracket: false,
									isSeriesBased: phase.isSeriesBased
								};
							});
							allRoundsToDisplay.push(...roundsToAdd);
						});
				}
			});

		this.allRounds$.next(allRoundsToDisplay);
	}

	ngOnChanges(): void {
		this.calcWeeks();
	}

	calcWeeks(): void {
		const movingPoint = Math.ceil(this.tiersTaken / 2);
		const allRoundsToDisplay = this.allRounds$.value;

		this.selectedRoundIndex = allRoundsToDisplay.findIndex((x) => x.name === this.selectedRound?.name);

		let startIndex = 0;
		let endIndex = 0;

		if (this.selectedRoundIndex < 0 + movingPoint) {
			// At the start and should not change
			startIndex = 0;
			endIndex = this.tiersTaken;
		} else if (this.selectedRoundIndex > allRoundsToDisplay.length - movingPoint) {
			// At the end
			startIndex = allRoundsToDisplay.length - this.tiersTaken;
			endIndex = allRoundsToDisplay.length;
		} else {
			// In the middle
			startIndex = this.selectedRoundIndex - movingPoint + 1;
			endIndex = this.selectedRoundIndex + movingPoint;
		}

		this.startIndex$.next(startIndex);
		this.endIndex$.next(endIndex);

		if (this.selectedRound == null) {
			let index = 0;

			if (this.eventStatus === "Complete" || this.eventStatus === "Archived") {
				index = allRoundsToDisplay.length - 1;
			}

			const sortGames = this.games
				.filter((x) => !!x.startTime && x.status === "Upcoming")
				.sort((a, b) => a.startTime!!.getTime() - b.startTime!!.getTime());

			if (sortGames.length > 0) {
				const firstGame = sortGames[0];

				index = allRoundsToDisplay.findIndex((x) => x.name === firstGame.round);
			}

			this.selectEvent(allRoundsToDisplay[index]);
		}
	}

	selectEvent(round: ICalendarRound): void {
		this.selectRoundEvent.emit(round);
	}

	updateIndex(offset: number): void {
		this.selectEvent(this.allRounds$.value[this.selectedRoundIndex + offset]);
	}

	isSelected(round: ICalendarRound): boolean {
		return this.selectedRound?.name === round.name;
	}

	private getDailyRounds(rounds: string[]): string[] {
		return Array.from(
			new Set(
				this.games
					.filter((x) => rounds.includes(x.round) && x.startTime)
					.map((x) => x.startTime!!.toLocaleDateString("en-US", { year: "2-digit", month: "2-digit", day: "2-digit" }))
			)
		);
	}
}
