@let isMobile = isMobile$ | async;
@let selectedRound = selectedRound$ | async;

@if (league$ | async; as league) {
	@if (event$ | async; as event) {
			<h2 class="header">{{ league.sport }} Schedule</h2>

			<am-schedule-calendar
				[tiers]="event.tiers"
				[games]="league.games"
				[phases]="event.phases"
				[eventStatus]="event.status"
				[selectedRound]="selectedRound"
				(selectRoundEvent)="selectedRound$.next($event)"
			></am-schedule-calendar>

		@if (selectedMatchups$ | async; as selectedMatchups) {
			@if (selectedRound) {
				@if (!selectedRound.isBracket) {
					<div class="games" [ngClass]="{ 'games-mobile': isMobile }">
						<am-schedule-matchup-box *ngFor="let matchup of selectedMatchups" [matchup]="matchup"></am-schedule-matchup-box>
					</div>
				} @else {
					<div [ngClass]="{ 'mobile-auction-width': isMobile, 'default-width': !isMobile }">
						<am-event-bracket
							[rounds]="selectedRound.rounds"
							[matchups]="selectedMatchups"
							[regions]="league.regions"
							[sport]="league.sport"
						></am-event-bracket>
					</div>
				}
			}
		}
	}
}
