import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { IUserEventGroup } from "src/app/models/user";
import * as userEventGroupActions from "./user-event-group.action";
import * as groupActions from "../group/group.actions";
import { logoutSuccess } from "../app/app.actions";

export interface UserEventGroupState extends EntityState<IUserEventGroup> {}

export function selectUserEventGroupId(a: IUserEventGroup): string {
	return a.groupId;
}

const adapter: EntityAdapter<IUserEventGroup> = createEntityAdapter<IUserEventGroup>({
	selectId: selectUserEventGroupId
});

export const InitialState: UserEventGroupState = adapter.getInitialState({});

const reducer = createReducer(
	InitialState,
	on(userEventGroupActions.upsertUserEventGroups, (state, { userEventGroups }) => adapter.setAll(userEventGroups, state)),
	on(groupActions.leaveGroupSuccess, (state, { groupId }) => adapter.removeOne(groupId, state)),
	on(logoutSuccess, () => InitialState)
);

export function userEventGroupReducer(state: UserEventGroupState | undefined, action: Action) {
	return reducer(state, action);
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of user ids
export const selectUserEventGroupIds = selectIds;

// select the dictionary of user entities
export const selectUserEventGroupEntities = selectEntities;

// select the array of users
export const selectAllUsersEventGroups = selectAll;

// select the total user count

export const selectTotalUserEventGroups = selectTotal;
