<ng-container *ngIf="groups$ | async as groups">
	<ng-container *ngIf="events$ | async as events">
		<mat-dialog-content class="content">
			<h2>Select a Group</h2>
			<mat-form-field appearance="outline" class="form-field">
				<mat-label>Group</mat-label>
				<mat-select [formControl]="selectedGroup">
					<mat-option *ngFor="let group of groups" [value]="group">
						<div class="option">
							<img width="40" height="40" [src]="'../assets/icons/logos/' + getEventType(group.eventId, events) + '.svg'" />
							<div class="group-name">{{ group.name }}</div>
						</div>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</mat-dialog-content>

		<mat-dialog-actions>
			<button mat-stroked-button color="warn" (click)="close()">Cancel</button>
			<button mat-raised-button class="left" color="accent" [disabled]="!this.selectedGroup.value" (click)="open()">Open</button>
		</mat-dialog-actions>
	</ng-container>
</ng-container>
