<am-loader [loading]="joining">
	<ng-container *ngIf="event$ | async as event">
		<h2>
			Public Rooms
			<mat-icon
				style="margin-left: 4px"
				matTooltip="Public rooms are rooms that can be joined by anyone, if the room does not fill up the auction will be cancelled."
			>
				info_outline
			</mat-icon>
		</h2>
		<ng-container *ngIf="publicGroups$ | async as publicGroups">
			<ng-container *ngIf="dataSource && dataSource.length > 0; else empty">
				<div id="public-rooms">
					<table mat-table [dataSource]="dataSource">
						<ng-container matColumnDef="name" *ngIf="!(isMobile$ | async)">
							<th mat-header-cell *matHeaderCellDef>Name</th>
							<td mat-cell *matCellDef="let element">
								<div class="name-container">
									<img
										width="40"
										height="40"
										[src]="'../assets/icons/logos/' + (event.type ? event.type.toLowerCase() : 'generic') + '.svg'"
									/>
									<div>{{ element.name }}</div>
								</div>
							</td>
						</ng-container>

						<ng-container matColumnDef="numOfManagers">
							<th mat-header-cell *matHeaderCellDef>Managers</th>
							<td mat-cell *matCellDef="let element">
								{{ getGroupCount(publicGroups, element) }} / {{ element.numOfManagers }}
							</td>
						</ng-container>

						<ng-container matColumnDef="auctionDate">
							<th mat-header-cell *matHeaderCellDef>Auction Time</th>
							<td mat-cell *matCellDef="let element">{{ element.auctionDate | date : "short" }}</td>
						</ng-container>
						<ng-container matColumnDef="buyIn">
							<th mat-header-cell *matHeaderCellDef>Buy In</th>
							<td mat-cell *matCellDef="let element">
								{{ getBuyInByScoringPreset(element.scoringPresetId, event) | currency : "USD" : "symbol" : "1.2-2" }}
							</td>
						</ng-container>
						<ng-container matColumnDef="action">
							<th mat-header-cell *matHeaderCellDef></th>
							<td mat-cell *matCellDef="let element">
								<div class="actions" *ngIf="currentUID$ | async as currentUID; else unauthJoin">
									<ng-container
										*ngIf="{ value: isCurrentInGroup(publicGroups, element, currentUID) } as isAlreadyInGroup"
									>
										<button
											mat-flat-button
											color="accent"
											[matTooltip]="
												isAlreadyInGroup.value
													? 'You are already in this group, click to view details'
													: 'Click to join'
											"
											(click)="openGroupInfo(element, event, currentUID, isAlreadyInGroup.value)"
										>
											{{ isAlreadyInGroup.value ? "View" : "Join" }}
										</button>
									</ng-container>
								</div>
								<ng-template #unauthJoin>
									<button mat-flat-button color="accent" (click)="login()">Join</button>
								</ng-template>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="(isMobile$ | async) ? mobileDisplayColumns : displayColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: (isMobile$ | async) ? mobileDisplayColumns : displayColumns"></tr>
					</table>
				</div>
			</ng-container>
			<ng-template #empty>
				<h1 style="text-align: center">There are not public rooms at this time, please try again later.</h1>
			</ng-template>
		</ng-container>
	</ng-container>
</am-loader>
