import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

export class DefaultRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void { }

  shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null { return null; }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
      return (future.routeConfig === curr.routeConfig) && future.data['reuseComponent'];
  }
}