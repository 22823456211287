import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { ISeries } from "src/app/models/game";
import { IRegion } from "src/app/models/league";
import { ITeam } from "src/app/models/team";

@Component({
	selector: "am-series-edit-dialog",
	standalone: true,
	imports: [
		CommonModule,
		MatDialogModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatSelectModule,
		MatInputModule,
		MatCheckboxModule,
		MatButtonModule
	],
	templateUrl: "./series-edit-dialog.component.html",
	styleUrl: "./series-edit-dialog.component.scss"
})
export class SeriesEditDialogComponent implements OnInit {
	form: FormGroup;
	series: ISeries;
	teams: ITeam[];
	regions: IRegion[];

	constructor(
		public dialogRef: MatDialogRef<SeriesEditDialogComponent>,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) private data: { series: ISeries; teams: ITeam[]; regions: IRegion[] }
	) {
		this.series = this.data.series;
		this.teams = this.data.teams;
		this.regions = this.data.regions;
	}
	ngOnInit(): void {
		this.form = this.fb.group({
			id: this.fb.control(this.series?.id || crypto.randomUUID()),
			status: this.fb.control(this.series?.status || undefined),
			team1Id: this.fb.control(this.series?.team1Id || undefined),
			team2Id: this.fb.control(this.series?.team2Id || undefined),
			team1Score: this.fb.control(this.series?.team1Score || undefined),
			team2Score: this.fb.control(this.series?.team2Score || undefined),
			round: this.fb.control(this.series?.round || undefined),
			winnerId: this.fb.control(this.series?.winnerId || undefined),
			displayOrder: this.fb.control(this.series?.displayOrder || undefined),
			regionId: this.fb.control(this.series?.regionId || undefined),
			clinchingAmount: this.fb.control(this.series?.clinchingAmount || undefined),
			totalNumberOfGames: this.fb.control(this.series?.totalNumberOfGames || undefined)
		});
	}

	save(): void {
		const series = {
			...this.series,
			...this.form.value
		} as ISeries;

		if (series.status === "Final" && series.team1Score && series.team2Score) {
			series.winnerId = series.team1Score > series.team2Score ? series.team1Id : series.team2Id;
		}

		this.dialogRef.close(series);
	}

	deleteSeries(): void {
		this.dialogRef.close({ deleteGame: true, id: this.series.id });
	}
}
