import { Injectable, NgZone } from "@angular/core";
import {
	Auth,
	FacebookAuthProvider,
	GoogleAuthProvider,
	OAuthProvider,
	User,
	authState,
	signInWithCredential,
	signInWithEmailAndPassword,
	signOut
} from "@angular/fire/auth";
import { Functions, httpsCallableData } from "@angular/fire/functions";
import {
	CreateUserWithEmailAndPasswordOptions,
	FirebaseAuthentication,
	GetIdTokenOptions,
	SignInResult
} from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";
import { Observable, ReplaySubject, lastValueFrom, take } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class AuthenticationService {
	private currentUserSubject = new ReplaySubject<User | null>(1);
	constructor(private readonly ngZone: NgZone, private fns: Functions, private auth: Auth) {}

	public get currentUser$(): Observable<User | null> {
		return authState(this.auth); // this.currentUserSubject.asObservable();
	}

	public async checkRedirectResult(): Promise<SignInResult | undefined> {
		if (Capacitor.isNativePlatform()) {
			return;
		}
		return await FirebaseAuthentication.getRedirectResult();
	}

	public getCurrentUser(): Promise<User | null> {
		return lastValueFrom(this.currentUser$.pipe(take(1)));
	}

	public async signInWithApple(): Promise<void> {
		const result = await FirebaseAuthentication.signInWithApple();

		if (Capacitor.isNativePlatform() && result?.credential?.idToken) {
			const provider = new OAuthProvider("apple.com");
			const credential = provider.credential({
				idToken: result.credential?.idToken,
				rawNonce: result.credential?.nonce
			});
			await signInWithCredential(this.auth, credential);
		}
	}

	public async signInWithFacebook(): Promise<void> {
		const result = await FirebaseAuthentication.signInWithFacebook();

		if (Capacitor.isNativePlatform() && result?.credential?.accessToken) {
			const credential = FacebookAuthProvider.credential(result.credential?.accessToken);
			await signInWithCredential(this.auth, credential);
		}
	}

	public async signInWithGoogle(): Promise<void> {
		const result = await FirebaseAuthentication.signInWithGoogle();

		if (Capacitor.isNativePlatform() && result?.credential?.accessToken) {
			const credential = GoogleAuthProvider.credential(result.credential?.idToken);
			await signInWithCredential(this.auth, credential);
		}
	}

	public async signInWithEmail(email: string, password: string): Promise<void> {
		await signInWithEmailAndPassword(this.auth, email, password);
	}

	public createUserAccount(email: string, password: string, displayName?: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "user-createAccount");
		return callable({ email, password, displayName }) as Observable<string>;
	}

	public resetPassword(email: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "user-resetPasswordEmail");
		return callable({ email }) as Observable<string>;
	}

	public resendVerificationEmail(email: string): Observable<string> {
		const callable = httpsCallableData(this.fns, "user-resendVerificationEmail");
		return callable({ email }) as Observable<string>;
	}

	public async updateAccount(displayName: string, photoUrl?: string): Promise<void> {
		await FirebaseAuthentication.updateProfile({ displayName, photoUrl });
	}

	public async signOut(): Promise<void> {
		await FirebaseAuthentication.signOut();
		await signOut(this.auth);
	}

	public async applyActionCode(oobCode: string): Promise<void> {
		await FirebaseAuthentication.applyActionCode({ oobCode: oobCode });
	}

	public async confirmPasswordReset(newPassword: string, oobCode: string): Promise<void> {
		await FirebaseAuthentication.confirmPasswordReset({ oobCode, newPassword });
	}
}
