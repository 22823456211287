import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { withLatestFrom, switchMap, map, catchError, filter, switchAll, take } from "rxjs/operators";
import * as profileActions from "./profile.actions";
import { Store } from "@ngrx/store";
import { from, of } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { ProfileState } from "./profile.reducers";
import { CloudMessagingService } from "src/app/services/cloud-messaging.service";
import { currentUID } from "../app/app.selectors";

@Injectable()
export class ProfileEffects {
	constructor(
		private actions$: Actions,
		private userService: UserService,
		private cloudMessagingService: CloudMessagingService,
		private store: Store<ProfileState>
	) {}

	getUserLegal$ = createEffect(() =>
		this.actions$.pipe(
			ofType(profileActions.getPrivateUserData),
			switchMap((action) => {
				return this.userService.getUserPrivateInfoById(action.uid).pipe(
					map((data) => {
						if (!data) {
							return profileActions.getPrivateUserDataSuccess({
								privateInfo: { privacyPolicyVersion: 0, termsAndConditionsVersion: 0, email: "" }
							});
						} else {
							return profileActions.getPrivateUserDataSuccess({ privateInfo: data });
						}
					}),
					catchError((x) => {
						return of(profileActions.getPrivateUserDataFailure({ errorMessage: x }));
					})
				);
			})
		)
	);

	updateEmailPreferences$ = createEffect(() =>
		this.actions$.pipe(
			ofType(profileActions.updateEmailPreference),
			withLatestFrom(this.store.select(currentUID)),
			switchMap(([action, uid]) =>
				this.userService.updateEmailNotificationSettings(uid, action.newValue).pipe(
					map((response) => profileActions.updateEmailPreferenceSuccess({ newValue: action.newValue })),
					catchError((err) => of(profileActions.updateEmailPreferenceError({ error: err })))
				)
			)
		)
	);

	registerForNotifications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(profileActions.registerPushNotifications),
			withLatestFrom(this.store.select(currentUID)),
			switchMap(([action, uid]) =>
				from(this.cloudMessagingService.request()).pipe(
					take(1),
					switchMap((response) =>
						this.userService.addPushNotificationDevice(uid, action.identifier, response).pipe(
							take(1),
							map(() => profileActions.registerPushNotificationsSuccess({ identifier: action.identifier, token: response }))
						)
					),
					catchError((err) => of(profileActions.registerPushNotificationsError({ error: err })))
				)
			)
		)
	);

	removeNotificationRegistrations$ = createEffect(() =>
		this.actions$.pipe(
			ofType(profileActions.removePushNotificationRegistrations),
			withLatestFrom(this.store.select(currentUID)),
			switchMap(([action, uid]) =>
				// add delete token to cloud messaging
				this.userService.removePushNotificationDevices(uid, action.tokens).pipe(
					map((response) => profileActions.removePushNotificationRegistrationsSuccess({ tokens: action.tokens })),
					catchError((err) => of(profileActions.removePushNotificationRegistrationsError({ error: err })))
				)
			)
		)
	);
}
