import { Injectable, Injector } from "@angular/core";
import { RemoteConfig, getAllChanges, isSupported, fetchAndActivate } from "@angular/fire/remote-config";

@Injectable({
	providedIn: "root"
})
export class ConfigService {
	remoteConfig: RemoteConfig;

	constructor(injector: Injector) {
		isSupported().then(() => {
			this.remoteConfig = injector.get(RemoteConfig);
			this.remoteConfig.settings.minimumFetchIntervalMillis = 300000;
			this.remoteConfig.defaultConfig = {};
			fetchAndActivate(this.remoteConfig);
		});
	}

	getConfig() {
		return getAllChanges(this.remoteConfig);
	}
}
