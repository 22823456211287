import { Component, computed, inject, Input, Signal } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { ITeam } from "src/app/models/team";
import { IUserEventGroup } from "src/app/models/user";
import { BaseComponent } from "src/app/shared/components/base.component";
import { ScoreboardPlacePipe } from "../../pipes/scoreboard-place.pipe";
import { MatDivider } from "@angular/material/divider";
import { NgClass, NgIf, NgFor, CurrencyPipe } from "@angular/common";
import { MatCard } from "@angular/material/card";
import { LeagueStore } from "src/app/state/league.store";

@Component({
	selector: "am-completed-group-card",
	templateUrl: "./completed-group-card.component.html",
	styleUrls: ["./completed-group-card.component.scss"],
	standalone: true,
	imports: [MatCard, NgClass, MatDivider, NgIf, NgFor, CurrencyPipe, ScoreboardPlacePipe]
})
export class CompleteGroupCardComponent extends BaseComponent {
	@Input() group: IUserEventGroup;
	@Input() isMobile: boolean | null = false;

	readonly leagueStore = inject(LeagueStore);
	teams: Signal<ITeam[] | undefined> = computed(() => {
		return this.leagueStore.activeLeagues().find((x) => x.id === this.group.leagueId)?.teams;
	});
	constructor(
		private router: Router,
		private store: Store
	) {
		super();
	}

	navigateToGroup(): void {
		this.router.navigate(["event", this.group.eventId, this.group.groupId]);
	}

	getTeam(teams: ITeam[], teamId: string): ITeam | undefined {
		return teams.find((x) => x.id === teamId);
	}
}
