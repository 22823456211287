<div id="edit-profile">
	<h2 mat-dialog-title>Edit Profile</h2>
	<mat-divider></mat-divider>
	<mat-dialog-content>
		<form [formGroup]="form">
			<mat-form-field class="max-width">
				<mat-label>Display Name</mat-label>
				<input formControlName="displayName" matInput />
			</mat-form-field>
		</form>
	</mat-dialog-content>
	<mat-divider></mat-divider>
	<mat-dialog-actions>
		<button mat-stroked-button color="warn" (click)="dialogRef.close()">Cancel</button>
		<button class="left" mat-flat-button color="primary" [disabled]="form.invalid" (click)="submit()">Submit</button>
	</mat-dialog-actions>
</div>
